import React from 'react'
import { withTheme } from '@material-ui/core/styles';


function IconTemplate (props) {
    return(
        <svg height="50" width="50" viewBox="0 0 24 24">
            <defs>
                <linearGradient id="gradient" gradientTransform="rotate(90)">
                    <stop offset="5%"  stopColor={props.theme.panelStartColor} />
                    <stop offset="95%" stopColor={props.theme.panelEndColor} />
                </linearGradient>
            </defs>
            <path d={props.path} fill={"url(#gradient)"} />
        </svg>
    )
}
export default withTheme(IconTemplate);