import React, {useState, Component} from 'react';

import { Chip } from '@material-ui/core/';
import { TableFilterList } from "mui-datatables";

export const CustomChip = ({ label, onDelete }) => {
    return (
        <Chip
            variant="outlined"
            color="primary"
            label={label}
            onDelete={onDelete}
        />
    );
};
const FilterList = (props) => {
    return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

export default FilterList