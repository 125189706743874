import React, {useState} from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider  } from '@material-ui/pickers';
import format from "date-fns/format";
import {enUS, de, fr} from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns';

export default function DatePicker (props){

    const locale= navigator.language || navigator.userLanguage;

    const localeFormatMap = {
        en: "MMMM d, yyyy",
        fr: "d MMM yyyy",
        de: "d MMM yyyy",
      };
      const localeMap = {
        en: enUS,
        fr: fr,
        de: de,
      };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
            <KeyboardDatePicker
                value={props.value}
                onChange={props.onChange}
                format={localeFormatMap[locale]}
                label={props.label}
                margin="normal"
                name={props.name}
                inputVariant={props.inputVariant}
                margin={props.margin}
            />
        </MuiPickersUtilsProvider>
    )

}