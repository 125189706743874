import React, {} from 'react';


import { Subscribe } from 'unstated-no-context-polyfill';
import ProgramContainer from '../../../../container/program/Program';
import TeamContainer from '../../../../container/program/Team';
import SequenceContainer from '../../../../container/program/Sequence';

import Waveform from './Waveform/Waveform';

export default function BottomPanelControl (props){
    return (
        <Subscribe to={[SequenceContainer, TeamContainer, ProgramContainer]}>
        {
            (sequence, team, program) => (
            <div style={{ height: "10%", width: "60%", position: "absolute", bottom: 0, boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)", 
                        display: "flex", justifyContent: "space-around"}}>
                <Waveform init={program.createWaveForm(sequence.getSequencePathByCount, sequence.getTopLevelSequences())} clearWaveform={program.clearTempData}/>

            </div>
        )}
        </Subscribe>

)}
