
import React, {useCallback, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

function Droppable (props){
  /*  const placeholder= (

    );*/
    const ref = useRef(null);

    const [{isDraggingOver}, drop] = useDrop({
        accept: props.type,
        collect: monitor => ({
            isDraggingOver: monitor.isOver(),
        }),
        drop(item, monitor) {
          let matRect = ref.current.getBoundingClientRect();
          let relativeParticipantPos = {
            x: (monitor.getSourceClientOffset().x - matRect.x)/matRect.width,
            y: (monitor.getSourceClientOffset().y - matRect.y)/matRect.width
          }
          if(props.onEndDrag)
            props.onEndDrag({id: item.id, relativePosition: relativeParticipantPos, getRef: item.getRef});
          return undefined
        },
      })


    const snapshot = {isDraggingOver}
    const provided = useMemo(() => ({
      innerRef: ref,
    //  placeholder,
    }),
    [props.droppableId, ref],);

    drop(ref);
    return (
        props.children(provided, snapshot)
    )
}

Droppable.propTypes = {
    onEndDrag: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    droppableId: PropTypes.string.isRequired
};

export default Droppable;



/*import React, {useCallback, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { useDrop } from 'react-dnd';
import Placeholder from './Placeholder';
import Participant from '../../components/elements/Team/Participant';


class Droppable
{
  constructor(props)
  {
   // this.snapshot = {isDraggingOver};
    this.provided= useMemo(() => ({
      //  placeholder,
      }),
      [this.props.droppableId]);
  }
  
    render = () =>this.props.children(this.provided, this.snapshot)
}

const specFunctions = {
    drop(props, monitor, component) 
    {
      let matRect = props.ref.current.getBoundingClientRect();
      let relativeParticipantPos = {
        x: (monitor.getSourceClientOffset().x - matRect.x)/matRect.width,
        y: (monitor.getSourceClientOffset().y - matRect.y)/matRect.width
      }
      console.log(component)
      if(props.onEndDrag)
        props.onEndDrag({id: "JM", relativePosition: relativeParticipantPos});
      return undefined
    },
}

const collect = (connect, monitor) => (
    {
      connectDropTarget: connect.dropTarget(),
      isDraggingOver: monitor.isOver(),
    }
)

Droppable.propTypes = {
    onEndDrag: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    droppableId: PropTypes.string.isRequired
};

export default DropTarget("ParticipantAvatar", specFunctions, collect)(Droppable);
*/