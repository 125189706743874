import React from 'react';
import { Subscribe } from 'unstated-no-context-polyfill';
import SequenceContainer from '../../container/program/Sequence'

import CounterTimeLine from './elements/sequence/CounterTimeLine';
import SequenceField from './elements/sequence/SequenceField';



class SequenceEditor extends React.Component {
    constructor (props){
        super(props);
        
        this.state = {
            hideNav: false,
        }

        this.CONSTGRID=15;
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }


    resize() {
        this.setState({hideNav: window.innerWidth <= 1080});
    }
    getGrid = (counts) => 
    {
        let grid;
        if (this.CONSTGRID * counts < 1000)
            grid = 1500/counts;
        else
            grid = this.CONSTGRID;
        return grid;
    }
    getWidth= ()=> 
    {
        return this.state.hideNav ? "auto" : (10/16*100);
    }


    render = () =>
    (    <Subscribe to={[SequenceContainer]}>
        {
            sequenceContainer =>(
                <div style={{overflowY: "auto", flexGrow: 1, height: "100%", width: "100%",position:"absolute"}}>
                    <div style={{marginTop: "1%", marginBottom: "1%", position: "relative"}}>
                        {!this.state.hideNav ?<CounterTimeLine grid={this.getGrid(sequenceContainer.state.visibleSequence.counts)}/> : null}
                        <SequenceField grid={this.getGrid(sequenceContainer.state.visibleSequence.counts)} width={this.getWidth()}/>
                    </div>
                    
                </div>)

            }
        </Subscribe>
    )

}
export default  SequenceEditor;