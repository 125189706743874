import React, {useState } from 'react';
import { withStyles,Button, makeStyles } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';


const StyledMenu = withStyles({
    paper: {
        background: "transparent",  
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
        margin: 5,
        background: "white",
        padding: "4px 16px",
        borderRadius: 4,
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1.5,
        height: 28,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: theme.invertedTextColor,

        },
        '&:hover':{
            background: "#d1d1d1",
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
              color: theme.invertedTextColor
            }

        }
       
    },
  }))(MenuItem);


export default function Dial(props) {
    const {component, className, classes, children, ...other} = props;
    const [isOverMenu, setIsOverMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () =>{
      setAnchorEl(null)
    }
    
    const onOpen = (e) =>{
      setAnchorEl(e.currentTarget)
    }
    const onLeaveComp = () =>{
      if(!isOverMenu)
        setTimeout(() => handleClose(), 1000);
    }
    const onLeaveMenu = () =>{
      setIsOverMenu(false);
      handleClose();
    }
    const handleClick = (fn) => () =>{
      if(fn)
        fn();
    }
    return (
        <React.Fragment>
            {React.createElement(component, {
              onClick: onOpen, 
              onMouseEnter: onOpen, 
              onMouseLeave: onLeaveComp, 
              className: className,
            })}
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              onMouseLeave={handleClose}
              MenuListProps={{
                onMouseEnter: () => setIsOverMenu(true),
                onMouseLeave: handleClose,
              }}
              {...other}
            >
            <StyledMenuItem >
                <ListItemText primary="Create program" />
            </StyledMenuItem>
            <StyledMenuItem >
                <ListItemText primary="Upload program" />
            </StyledMenuItem>
            </StyledMenu>
        </React.Fragment>
    );
  }