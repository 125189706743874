
import React, {useRef, useState, useEffect, createRef} from 'react';
import { withTheme, Button } from '@material-ui/core';

import ParticipantDraggable from './Participant/ParticipantDraggable';
import {useSprings, animated} from 'react-spring'


function Mat (props) {

    const keysPartPos = Object.keys(props.participantsStatus);
    const [isInitialRenderd, setIsInitialRendered] = useState(false);
    const [isPlayedBefore, setIsPlayedBefore] = useState(false);
    const [size, setSize] = useState({x: 0, y:0});
    const [prevRel, setPrevRel] = useState(1);
    const springRefs = React.useRef([]);
    const ref =useRef(null);

    if (springRefs.current.length !== keysPartPos.length) {
        // add or remove refs
        springRefs.current = Array(keysPartPos.length).fill().map((_, i) => springRefs.current[i] || createRef());
    }

    const [springs, set, stop] = useSprings(keysPartPos.length, index => {
        return(
        {
            left: props.participantsStatus[keysPartPos[index]].startPos.x * size.x, 
            top: props.participantsStatus[keysPartPos[index]].startPos.y * size.y, 
            position: "absolute",
        })})
    
    if(props.updateAvailable)
    {
        props.setUpdateStatus(false);
        set(index => {
            if(props.updatePartAvailable[keysPartPos[index]])
            {
                props.updatePartAvailable[keysPartPos[index]] = false;
                return{
                    from:{
                        left: props.participantsStatus[keysPartPos[index]].startPos.x * size.x,
                        top: props.participantsStatus[keysPartPos[index]].startPos.y * size.y,
                        position: "absolute"
                    },
                    to: {
                        left: props.participantsStatus[keysPartPos[index]].endPos.x * size.x, 
                        top: props.participantsStatus[keysPartPos[index]].endPos.y * size.y,
                        position: "absolute"
                    },
                    config: {duration: props.participantsStatus[keysPartPos[index]].duration*1000},
                }
            }
        })   
    }

    useEffect(() => {
        if(!isInitialRenderd)
        {
            const s = ref.current.clientHeight;
            let newSize = {x: s* props.ratioX2Y, y: s};
            setSize(newSize)
            setPrevRel(props.ratioX2Y);
            setIsInitialRendered(true);
            set (index => ({
                left: props.participantsStatus[keysPartPos[index]].startPos.x * newSize.x, 
                top: props.participantsStatus[keysPartPos[index]].startPos.y * newSize.y, 
                position: "absolute"
            }));
        }
        else if(prevRel != props.ratioX2Y)
        {
            let newSize = {x: size.y*props.ratioX2Y, y: size.y };
            setPrevRel(props.ratioX2Y);
            setSize(newSize);
        } 
        set({pause: !props.isPlaying})
    },[props.isPlaying, props.updateAvailable]);



    const handleRightClick = (e) =>
    {
        e.preventDefault();
        if(!props.isPlaying)
        {
            props.selectParticipant(e.currentTarget.id);
            props.handleRightClick(e);
        }

    }
    const handleLeftClick = (e) =>{
        e.stopPropagation(); //no further Click-Events
        props.changeSelectionParticipant(e.currentTarget.id);
    }

    const unselect = (e) => {
        props.unselectParticipants();
    }

    return(   
        <div ref={ref} id="showarea" style={{height: isInitialRenderd ? size.y : props.sizeY,
            width: isInitialRenderd ? size.x : 0, background: props.theme.fieldColor, position:"relative"}} onClick={unselect}>  
            {
                springs.map((style, i) => 
                <animated.div style={style} id={keysPartPos[i]} key={i} onContextMenu={handleRightClick} onClick={handleLeftClick}>
                    <ParticipantDraggable key={i} participant={props.participants[keysPartPos[i]]} 
                        currentMatData={props.participantsStatus[keysPartPos[i]]} forbidDrag={props.isPlaying}
                    />
                </animated.div>)
            }

        </div>
    )
};

export default  withTheme(Mat);