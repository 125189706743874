const createDiamond = (height, width, color) => {
  const diamond = document.createElement("div");
  const top = document.createElement("div");
  top.style.cssText = "width: 0;"+
  "height: 0; "+
  "border: "+ width*0.5 +"px solid transparent; "+
  "border-bottom: "+height*0.5+"px solid " + color +"; "+
  "position: relative; "+
  "top: "+ width*-0.5 +"px; ";

  const bottom = document.createElement("div");
  bottom.style.cssText = "content: '';"+ 
  "position: absolute; "+
  "left: 0px; "+
  "top: "+ height*0.5+"px; "+
  "width: 0;"+ 
  "height: 0; "+
  "border: "+ width*0.5 +"px solid transparent;"+ 
  "border-top: "+ height*0.5+"px solid " + color +";";
  diamond.appendChild(top);
  diamond.appendChild(bottom);
  return diamond;
}

export default class WaveSurferKeyframes {

    keyframes = [];
    keyframesEl = [];
    static create = (params) => {
        return {
            name: 'keyframes',
            deferInit: params && params.deferInit ? params.deferInit : false,
            params: params,
            staticProps: {
                staticMethod() {
                    // this will be added to the wavesurfer instance and can then be called
                    // wavesurfer.staticMethod();
                },
                addKeyframe(keyframe){
                  this.keyframes.addKeyframe(keyframe)
                },
                setKeyframes(keyframes){
                  this.keyframes.setKeyframes(keyframes)
                },
                clearKeyframes(){
                  this.keyframes.clearKeyframes();
                },
                removeKeyframe(pos){

                }
            },
            instance: WaveSurferKeyframes
        };
    }

    constructor(params, ws) {
        this.wavesurfer = ws;
        this.keyframes = params.keyframes ? params.keyframes : [];
    }

    init() {
        this.checkIfWSElementLoaded((elem) => {
            this.waveContainer = elem;
            
          });
        this.wavesurfer.on("ready", () => { this.onReady(); });
    }

    checkIfWSElementLoaded = (onLoaded)  => {
        const childNodes = this.wavesurfer.container.childNodes;
        if (0 < childNodes.length) {
          onLoaded(childNodes[0]);
        } else {
          window.setTimeout(() => {
            this.checkIfWSElementLoaded(onLoaded);
          }, 100);
        }
      }

    addKeyframe(keyframe){
      let keyframeCalc = {...keyframe};
      
      keyframeCalc.pos = this.waveContainer.offsetWidth* keyframe.pos/this.wavesurfer.getDuration() -5
      this.keyframes.push(keyframeCalc)
      if(this.loaded)
        this.render();
    }
    setKeyframes = (keyframes) => {
      this.clearKeyframes();
      keyframes.forEach (k =>{
        k.pos = this.waveContainer.offsetWidth* k.pos/this.wavesurfer.getDuration() -5
        this.keyframes.push(k)
      })
      if(this.loaded)
        this.render();
    }
    clearKeyframes = () =>{
      this.keyframes = [];
      this.keyframesEl.forEach(el =>{
        this.waveContainer.removeChild(el);
      })
      this.keyframesEl=[];
    }

    render = () =>{
        if (this.waveContainer === null) {
            return;
        }
        const height=40;
        const width=10;

        this.keyframes.forEach(k =>
        {
          const keyframe = createDiamond(height, width, k.color);//document.createElement("div");
          keyframe.style.cssText = 
          "position: absolute;" +
            `top:  ${this.waveContainer.offsetHeight*0.5-height/2}px;` +
            "left: " + k.pos + "px;"+
            "z-index: 3";
          this.waveContainer.appendChild(keyframe);
          this.keyframesEl.push(keyframe);
        })
        
    }

    onReady = () => {
      this.loaded=true;
      this.render();
    }

    destroy = () => {
      // stop doing something
    }
}

/*
#diamond { 
    width: 0;
    height: 0; 
    border: 5px solid transparent; 
    border-bottom: 15px solid #037CA9; 
    position: relative; 
    top: -5px; 
} 
 
#diamond:after { 
    content: ''; 
    position: absolute; 
    left: -5px; 
    top: 15px; 
    width: 0; 
    height: 0; 
    border: 5px solid transparent; 
    border-top: 15px solid #037CA9;
} */