import React from 'react';

//import { TextField } from '@material-ui/core';

import ParticipantContainer from './elements/mat/Participant/ParticipantContainer';
import TopPanelControl from './elements/mat/TopPanelControl';
import LeftPanelControl from './elements/mat/LeftPanelControl';
import BottomPanelControl from './elements/mat/BottomPanelControl';

import DragDropContext from '../../lib/DnD/DragDropContext';
import {resetDragLayer} from './elements/mat/Participant/ParticipantDragLayer';
import MatDroppable from './elements/mat/MatDroppable';

class MatEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            success: null,
        } 
    }
    
    componentDidMount () {
        resetDragLayer();
    }

    render() {
        return (
            <div style={{height:"100%", width: "100vw", display: "flex",position: "absolute"}}>
                <div style={{display: "flex",width: "100%", position: "relative", justifyContent: "center", alignItems:"center"}}>
                    <LeftPanelControl/>
                    <TopPanelControl/>
                    <DragDropContext>
                        <MatDroppable sizeY="80%"/>
                        <ParticipantContainer/>
                    </DragDropContext>
                    <BottomPanelControl />
                </div>
            </div>
        );
    }
}

export default MatEditor;