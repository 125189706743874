import React from 'react';
import './App.css';
import RouterSwitch from '../router/router.jsx'
import themeInstance from '../themes/core';
import {
  ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles';


function App() {
  return (
    <MuiThemeProvider theme={themeInstance}>
      {console.log(window.innerHeight)}

      <div className="App" style={themeInstance.app}>
        <header className="App-header">
          <RouterSwitch />
        </header>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
//
//