import React, {useState, useEffect} from 'react';

import {Container, Button, Paper,makeStyles, Typography, TableContainer, Table,TableRow,TableBody, TableHead, TableCell, CircularProgress} from '@material-ui/core/';
import MusicUpload from './MusicUpload';
import { round } from '../../../../lib/Utilities';
import { getSimpleCountByTime } from '../../../../lib/ProgramCalculations';
import { withFirebase } from '../../../../container/firebase';
import CircularProgressWithLabel from './CircularProgressWithLabel';
const useStyles = makeStyles({
    container: {
        width: "70%",
        margin: 40,
        padding: 10
    },
});


function MusicSide (props) {
    const [isMusicLoaded, setMusicLoaded] = useState(false);
    const [progress, setProgress] = useState(null);
    const [file, setFile] = useState(null);
    const [musicData, setMusicData] = useState({
        bpm: props.musicData,
        startInS: props.startInS,
        ...props.musicData
    });

    const classes = useStyles();
    let buffer;

    const setMusic = (m) =>
    {
        //getActiveProgram().eightCounts
        let offsetInS = 5.4;

        let music = {};
        music.bpm = m.bpm;
        music.duration = m.duration;
        music.offsetInS = offsetInS;
        music.name = m.name;
        music.lastModifiedDate = m.lastModifiedDate;
        music.counts = getSimpleCountByTime(m.duration-offsetInS, m.bpm);
        buffer = m.buffer.slice(0);

        setFile(m.file);
        props.setBuffer(buffer);
        setMusicData(music);
        setProgress(null);
        setMusicLoaded(true);
    }

    const setDataProgress = (progress) =>{
        setProgress(progress);
    }

    const getDataRows = () =>{
        const rows = [
            {label: "Last modified:", value: musicData.lastModifiedDate ?musicData.lastModifiedDate.toLocaleString() : null},
            {label: "BPM", value: musicData.bpm},
            {label: "Duration", value: round(musicData.duration, 2) + "s"},
            {label: "Begin Music", value: round(musicData.offsetInS, 2) + "s"},
            {label: "Counts", value: musicData.counts + " -> " + Math.floor(musicData.counts/8) + ":" + ((musicData.counts)%8)},
        ];
        return rows.map((r, i) => r ? <TableRow key={i}><TableCell>{r.label}</TableCell><TableCell>{r.value}</TableCell></TableRow>:null);
    }

    const takeOverValues = async () =>{
        let musicUrl = await props.firebase.uploadMusic(props.programId)(setDataProgress)(file);
        props.setData({...musicData, musicUrl});
    }

    return (
        <React.Fragment>
            <MusicUpload setDataFn={setMusic}></MusicUpload>
            {isMusicLoaded ?
                <TableContainer component={Paper} className={classes.container}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>{musicData.name}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getDataRows()}
                        </TableBody>
                    </Table>
                    <Button onClick={takeOverValues}>Upload Music and take over Values</Button>
                    {progress !== null && progress !== 100 ? 
                    <CircularProgressWithLabel value={progress}/> : null}
                </TableContainer>
                : null
            }

        </React.Fragment>
    )
}


export default withFirebase(MusicSide);
/*<Paper className="infopaper" style={{margin: 40, padding: 20}}>
                    <Typography>Name: {musicData.name}</Typography>
                    {musicData.lastModifiedDate ? <Typography>Last Modified: {musicData.lastModifiedDate.toLocaleString()}</Typography>:null}
                    <Typography>Duration: {musicData.duration + "s"}</Typography>
                    <Typography>bpm: {musicData.bpm + "s"}</Typography>
                    <Typography>Music Starts at: {musicData.cheerEndInS + "s"}</Typography>
                </Paper>*/