import React, {useState} from 'react';
import { Dialog, DialogActions, DialogContent,DialogTitle, Button, IconButton } from '@material-ui/core';
import { Subscribe } from 'unstated-no-context-polyfill';

import TeamContainer from '../../../../container/program/Team';
import SequenceContainer from '../../../../container/program/Sequence';
import DeleteIcon from '@material-ui/icons/Delete';
export default function SequenceConfigDialog (props){

    const [sequenceProps, setSequenceProps] = useState(JSON.parse(JSON.stringify(props.sequence)));
  //  const [teamTable, setTeamTable]  = useState();
    const closeDialog = () => props.handleClose();

    const resetDialog = () => {        
    }
    const cancelDialog = () => {
        resetDialog();
        closeDialog();
    }

    return (
        <Subscribe to={[SequenceContainer, TeamContainer]}>
        {
            (sequence, team) => (
            <React.Fragment>
                <Dialog open={props.isOpen} onClose={props.handleClose} aria-labelledby="simple-dialog-title"  fullWidth={true}>

                <DialogTitle id="simple-dialog-title">{sequenceProps.name}</DialogTitle>
                <IconButton name={props.index + "$" +props.groupName} size="small" style={{position: "absolute", display: "block", right:"0px", margin: 20}} onClick={sequence.removeSequence}>
                    <DeleteIcon/>
                </IconButton>
                <DialogContent >
                    
                    
                </DialogContent>
                <DialogActions>
                    <Button name="newParticipantDialogOpen" onClick={cancelDialog}>
                    Cancel
                    </Button>
                    <Button variant="contained" name="newParticipantDialogOpen" onClick={cancelDialog} >
                    Save
                    </Button>
                </DialogActions>
                </Dialog>
            </React.Fragment>)
        }
        </Subscribe>
    )
};
