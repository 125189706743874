import React, {useEffect} from 'react';

import { withTheme } from '@material-ui/core/styles';
import { Subscribe } from 'unstated-no-context-polyfill';
import MenuContainer from './../../../container/Menu'; 
import { AppBar, Toolbar, Typography, IconButton, Grid,Avatar,makeStyles } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SaveIcon from '@material-ui/icons/Save';
import {blueGrey} from '@material-ui/core/colors';

import { Link, withRouter } from 'react-router-dom';
import * as ROUTES from './../../../router/routes';


import TeamContainer from 'container/Team';

const useStyles = makeStyles((theme) => ({
    user: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        color: theme.palette.getContrastText(blueGrey[500]),
        backgroundColor: blueGrey[500],
        boxShadow: `0 0 2px 1px ${theme.palette.getContrastText(blueGrey[500])}`,
      },

}));

function TopBarSeq (props) {
    const classes = useStyles();
    let team = TeamContainer.useContainer();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };


    const routeBackToTeamSelection = () => {
        props.history.push(ROUTES.MAIN, {selectedTab: 1});
    }


    return (
        <AppBar position="sticky" >
            <Toolbar>
                <Grid container justify="space-between" alignItems= 'center'>
                    <Grid item>
                        <IconButton edge="start" aria-label="menu" onClick={routeBackToTeamSelection}>
                            <NavigateBeforeIcon />
                        </IconButton>
                    </Grid>
                    <Grid item >
                        <Typography variant="h5" style={{display: 'inline-block'}} >
                            {team.generalData.name}
                        </Typography>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

function TopBarSkeleton () {
    return (<AppBar position="sticky" ><Toolbar/></AppBar>)
}

export {TopBarSkeleton}
export default withTheme(withRouter(TopBarSeq));
