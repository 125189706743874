
import * as firebase from 'firebase';
import { rejects } from 'assert';
import {asyncForEach} from 'lib/Utilities'

const prodConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
 
const devConfig = {
    apiKey: process.env.REACT_APP_DEV_API_KEY,
    authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
    projectId: process.env.REACT_APP_DEV_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_DEV_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_DEV_APP_ID,
    measurementId: process.env.REACT_APP_DEV_MEASUREMENT_ID
}

const config = process.env.NODE_ENV === 'production' ? devConfig:devConfig; //prodConfig : devConfig;



class Firebase{
  constructor(props = {}) {
    firebase.initializeApp(config);
    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.storage = firebase.storage();
  }


  /////////////////////////////////////////////////////
  //////////////////      LOGIN      //////////////////
  /////////////////////////////////////////////////////
  doSignInWithEmailAndPassword = (email, password) =>{
    this.auth.signInWithEmailAndPassword(email, password);
  }

  doLogOut = () => this.auth.signOut();

  async getDataOfCollection (root, mappingFn = this.mapping) {
    return new Promise((resolve, rejects) => {
      let result=[];
      root.get().then(querySnapshot =>
      {
        querySnapshot.forEach(doc =>
        {
          let data = mappingFn(doc.data());
          data.id = doc.id;
          result.push(data);
        })
      }).then (() => resolve(result))
    })
  }

  mapping = data => {
    return {...data}
  }





  /////////////////////////////////////////////////////
  //////////////////        MENU       ////////////////
  /////////////////////////////////////////////////////
  async getInitialData() {
    return new Promise((resolve, rejects) => {
      var userRef = this.db.collection("users").doc(this.auth.currentUser.uid);
      userRef.get().then (async user =>{
        let result={};
        result.userName = user.data().name;
        result.userTag = user.data().tag;
        result.clubName = user.data().clubName;
        this.userData = result;
        this.club = this.db.collection("clubs").doc(user.data().clubRef.id);
        this.clubStorage = this.storage.ref().child(user.data().clubRef.id);
        resolve(result);
      });
    });
  }
  
  getTeams = async () => {
    return await await this.getDataOfCollection(this.club.collection("teams"));
  }

  getMenuData = async () => {
    return new Promise( async (resolve, rejects) => {
      var result= {};
      result.programs = await this.getDataOfCollection(this.club.collection("programs"), data => ({...data, lastEdit: data.lastEdit ? data.lastEdit.toDate() : ""}));
      result.teams = await this.getDataOfCollection(this.club.collection("teams"));
      resolve(result);
    })
  }





  /////////////////////////////////////////////////////
  //////////////////  TEAM - ATHLETES  ////////////////
  /////////////////////////////////////////////////////
  createNewTeam = async () => {
      let newTeamRef = await this.club.collection("teams").doc();
      return newTeamRef.id;
  }

  getTeamData = async (teamId) => {
    return new Promise((resolve, rejects) => {
      this.club.collection("teams").doc(teamId).get().then(team => 
      {
        let result = team.data();
        result.id = team.id;
        resolve(result)
      })
    });

  }

  saveTeamData = async (team) => {
    return new Promise((resolve, rejects) => {
      let teamCopy = {...team};
      delete teamCopy.id;
      this.club.collection("teams").doc(team.id).set(teamCopy, { merge: true }).then(resolve());
    });
  }

  getAthletsOfTeam = async (teamId, asObject=false) =>{
    return new Promise((resolve, rejects) => {
      this.club.collection("teams").doc(teamId).collection("athletes").get().then(athlets => 
      {
        let result;
        if(asObject)
        {
          result = {};
          athlets.forEach(athlete => result[athlete.id] = {...athlete.data(), birthdate:  athlete.data().birthdate && athlete.data().birthdate.toDate? athlete.data().birthdate.toDate() : null});
        }
        else
        {
          result = [];
          athlets.forEach(athlete => result.push({...athlete.data(), id: athlete.id, birthdate:  athlete.data().birthdate && athlete.data().birthdate.toDate? athlete.data().birthdate.toDate() : null}));
        }
        
        
        resolve(result)
      })
    });
  }

  saveAthlet = teamId => async athlete =>{
    return new Promise((resolve, rejects) => {
      let athleteCopy = {...athlete};
      
      if (athlete.id)
      {
        delete athleteCopy.id;
        this.club.collection("teams").doc(teamId).collection("athletes").doc(athlete.id)
        .set(athleteCopy)
        .then(() => resolve(athlete.id));
      }
      else
      {
        this.club.collection("teams").doc(teamId).collection("athletes")
        .add(athleteCopy)
        .then(docRef => resolve(docRef.id));
      }
    });
  }

  deleteAthletes = teamId => async athletesIds =>{
    athletesIds.map (id => {
      this.club.collection("teams").doc(teamId).collection("athletes").doc(id).delete();
    })
  }




  /////////////////////////////////////////////////////
  ////////////////        PROGRAM       ///////////////
  /////////////////////////////////////////////////////
  createNewProgram = async () => {
      let newProgramRef = await this.club.collection("programs").doc()
      return newProgramRef.id;
  }

  getProgram = async (programId) => {
    return new Promise(async (resolve, rejects) => {
      let content = this.club.collection("programs").doc(programId).collection("content");
      let program = {};
      program.team = {};

      await this.club.collection("programs").doc(programId).get().then(doc => program.general = doc.data())
      await content.doc("participants").get().then(doc => program.team.participants = doc.data());
      await content.doc("paths").get().then(doc => program.paths = doc.data());
      let allTeams = await this.getTeams();

      program.team.activeTeamTemplates = [];
      program.team.availableTeamTemplates = [];

      await asyncForEach(program.general.teams, async teamId => {
        let index = allTeams.findIndex(t => t.id === teamId);
        let template = allTeams.splice(index, 1)[0];
        template.athletes = await this.getAthletsOfTeam(teamId, true);
        program.team.activeTeamTemplates.push(template);
      })

      program.team.activeTeamTemplates.forEach(team =>
        Object.keys(team.athletes).forEach(key => {
          if(!program.team.participants.inactive[key] && !program.team.participants.active[key])
            program.team.participants.inactive[key] = {...team.athletes[key], id: key};
        })
        )
      program.team.availableTeamTemplates = [...allTeams];
      resolve(program)
    });
  }

  saveProgram = (programId) => async data => {
    return new Promise(async (resolve, rejects) => {
      if (programId)
      {
        await this.club.collection("programs").doc(programId).set(data.general);
        let contentCol = this.club.collection("programs").doc(programId).collection("content");
        contentCol.doc("participants").set(data.team);
        contentCol.doc("paths").set(data.paths);
     }

      resolve (null);
    });
  }


  
  /////////////////////////////////////////////////////
  ////////////////      STORAGE      //////////////////
  /////////////////////////////////////////////////////
  uploadMusic = programId => updateFn => async file => {
    return new Promise(async (resolve, rejects) => {
      let spaceRef = this.clubStorage.child(programId);
      let task = spaceRef.put(file);
      task.on ('state_changed', snapshot => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        updateFn(progress);
      }, ()=>{}, ()=> resolve(task.snapshot.ref.getDownloadURL()));
    });
  }
}
 
export default Firebase;

