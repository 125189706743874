import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Tabs,Tab } from '@material-ui/core/';

import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import DoneIcon from '@material-ui/icons/Done';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import TimelineIcon from '@material-ui/icons/Timeline';
import PhotoSizeSelectSmallIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';


import { Subscribe } from 'unstated-no-context-polyfill';
import ProgramContainer from '../../../container/program/Program';
import TeamContainer from '../../../container/program/Team';
import SequenceContainer from '../../../container/program/Sequence';

const useStyles = makeStyles({
    root: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
    },
  });
  

export default function EditingFooter(props) {
    const classes = useStyles();

    return (
        <Subscribe to={[SequenceContainer, TeamContainer, ProgramContainer]}>
        {
            (seq, team, program) =>(
        <React.Fragment>
            <div style={{height:"72px"}}/>
            <Tabs
                value={props.value}
                onChange={props.onChange}
                variant="fullWidth"
                aria-label="icon label tabs example"
                className={classes.root}
            >
                <Tab label="General" icon={<SettingsIcon />} />
                <Tab label="Team" icon={<GroupIcon />} />
                <Tab label="Music/BPM" icon={<MusicNoteIcon />} />
                {program.state.containsChant ? <Tab label="Chant" icon={<RecordVoiceOverIcon />} /> : null}
                <Tab label="Sequence" icon={<TimelineIcon />} />
                <Tab label="Mat" icon={<PhotoSizeSelectSmallIcon />} />
            </Tabs>
            </React.Fragment>
            )
        }
        </Subscribe>
    );
  }
  // <Tab label="Finish" icon={<DoneIcon />} />