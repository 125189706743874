import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import TeamContainer from '../../../../container/program/Team';
import SequenceContainer from '../../../../container/program/Sequence';
import { Subscribe } from 'unstated-no-context-polyfill';

import Participant from './Participant';

import { Droppable } from "react-beautiful-dnd";
import { withTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    list: {
        overflowY: "scroll",
        width: "100%",
        height: "100vh",
        display: "flex",
        padding: "20px",
        margin: 0,
        alignContent: "flex-start",

    }
}));


function ActiveParticipantField() {
    const classes = useStyles();

    const getListStyle = (isDraggingOver) => ({

        width: "100%",
        height: "100%",

        display: "flex",
        padding: "20px",
        margin: 0,
        alignContent: "flex-start",
        overflowY: "scroll"


    })

    const onDragEnd = () => ({

    })
    return (
        <Subscribe to={[SequenceContainer, TeamContainer]}>
            {
                (sequence, team) => (
                    <Droppable droppableId="active" direction="horizontal">
                        {(provided, snapshot) => (
                            <React.Fragment>
                                <Typography style={{ margin: 10 }}>Active Participants</Typography>

                                <Grid container spacing={3}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={classes.list}
                                >
                                    {
                                        team.state.participants.active ?
                                            Object.keys(team.state.participants.active).map((key, i) => {
                                                let p = team.state.participants.active[key];
                                                return <Participant key={i} index={i} participant={p} isActive={true} />;
                                            })
                                            : null
                                    }
                                    <span style={{ display: "none" }}>
                                        {provided.placeholder}
                                    </span>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Droppable>
                )
            }

        </Subscribe >)

}
export default withTheme(ActiveParticipantField);