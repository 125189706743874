import React,{useState} from 'react';
import { makeStyles, Box, Container, Typography, TextField, Button } from '@material-ui/core/';
import TeamContainer from 'container/Team';
import {withFirebase} from 'container/firebase'

const useStyles = makeStyles((theme) => ({
    panel:{
        width: "100%",
        height: "100%",
        padding: theme.spacing(3),
        background: theme.palette.background.dark
    },
    box: {
        paddingTop: theme.spacing(12),
    },
    input: { 
        margin: theme.spacing(3)
    }
}));


function Settings (props) {
    const classes = useStyles();
    let team = TeamContainer.useContainer();

    const [data, setData] = useState(team.generalData);
    console.log(team.generalData)
    const onInputChange = (e) => {
        let dataCopy = JSON.parse(JSON.stringify(data));
        dataCopy[e.currentTarget.name] = e.currentTarget.value;
        setData(dataCopy);
    }
    
    const onSave = (e) => {
        team.updateGeneralData(data);
        props.firebase.saveTeamData(data);
    }

    const onReset = (e) => {
        setData(team.generalData);
    }

    return (
       <Container className={classes.panel}>
           <Typography variant="h5">Settings</Typography>
            <Box className={classes.box}>
                <TextField className={classes.input} autoComplete="off" required label="Name" margin="dense" name={"name"} value={data.name} onChange={onInputChange} variant="filled"/>
                <TextField className={classes.input} autoComplete="off"  required label="Level" margin="dense" name={"level"} value={data.level} onChange={onInputChange} variant="filled"/>
            </Box>
            <Box>
                <Button onClick={onReset}>Reset</Button>
                <Button variant="contained" onClick={onSave}>Save</Button>
            </Box>
       </Container>
    );
}


export default withFirebase(Settings);