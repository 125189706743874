import React, {useState} from 'react';
import { TextField } from '@material-ui/core';


export default function CountInputField (props) {
    const [counts, setCounts] = useState(props.sequence.counts);
    const [isValid, setIsValid] = useState(props.sequence.counts);


    const onChange = (e) => 
    {
        if (e.currentTarget.value === "")
        {
            setCounts("");
            setIsValid(false);
            return;
        }
        let c = parseInt(e.currentTarget.value);
        setCounts(c);
        setIsValid(props.onChange(e));
    }

    return <TextField  margin="dense" error={!isValid} style={{width: "10%", padding: 5}} label="Counts" name={props.name}
    value={counts} onChange={onChange} variant="outlined" type="number"/>
}