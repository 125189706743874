
import React from 'react';
import { Container, Subscribe } from 'unstated-no-context-polyfill';
import * as firebase from 'firebase';

class MenuContainer extends Container  {

    constructor(props = {}) {
        super();
        this.state = {
            userName: "",
            userTag: "",
            clubName: "CCH",
           /* programs: [
                {name: "Falcons 2020", counts: "321", bpm: "134", participants: "24", lastEdit: "27th May, 2020", teams: ["Falcons"]},
                {name: "Falcons 2019", counts: "386", bpm: "160", participants: "23", lastEdit: "20th May, 2019", teams: ["Falcons"]},
                ],//[],
            teams: [
                {name: "Falcons", level: "Coed 5", stats:{ amount: 23, Flyer: 10, Tumbler: 15, Base: 15}, id: "tjMwSsEQned0449kOJbI"}
            ], //[]*/
            programs: [],
            teams: [],
            selectedProgramIndex: null,
        };
        this.db = firebase.firestore();
    }
    
    getTeamId = index => {
        return this.state.teams[index].id;
    }
    setGeneralUserData = data =>{
        this.setState(data);
    }

    selectProgram = index => {
        this.setState({selectedProgramIndex: index})
    }
    getSelectedProgram = () => {
        return this.state.programs[this.state.selectedProgramIndex];
    }
    /*=======================================================================*/
    /*========================         MAT         ==========================*/
    /*=======================================================================*/

}

export const withMenu = Component => props => (
    <Subscribe to={[MenuContainer]}>
      {menu => <Component {...props} menu={menu} />}
    </Subscribe>
);
 

export default MenuContainer;
