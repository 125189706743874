
import React, {useState, useMemo} from 'react';

import Droppable from '../../../../lib/DnD/Droppable';
import Mat from './Mat';

import { Subscribe } from 'unstated-no-context-polyfill';
import ProgramContainer from '../../../../container/program/Program';
import TeamContainer from '../../../../container/program/Team';
import SequenceContainer from '../../../../container/program/Sequence';

import {withProgram} from '../../../../container/program/ProgramContext';


import ParticipantMenu from './Participant/ParticipantMenu';
import WaypointManagerDialog from './WaypointManagerDialog';
//import PositionHoldPopover from '../../../../deprc/PositionHoldPopover';

function MatDroppable (props) {
    const [isDialogOpen, setDialogOpen] = React.useState(false);

    const onEndDrag = (setWaypoint, removeNotSettedParticipant) => (e)=>{
        removeNotSettedParticipant(e.id);
        setWaypoint(e);
       /* let ref = e.getRef().current;
        setTimeout(()=>{
            setAnchorEl(e.getRef().current);
        }, 200);*/
        
    }
    const [anchorEl, setAnchorEl] = useState(null);

    const openPartMenu = (e)=>{
       setAnchorEl(e.currentTarget)
    }
    const setAnchorNull = () =>{
       setAnchorEl(null)
    }

    const handleRightClick = (e) =>{
        e.preventDefault();
    }

    const openWaypointDialog = (e) =>{
        setDialogOpen(true);
    }
    const closeWaypointDialog = (e) =>{
        setDialogOpen(false);
    }
    let program = props.program;
    let team = props.team;
    let sequence = props.sequence;

    const memoMat = useMemo(() => (
        <Mat sizeY="100%" ratioX2Y={program.state.matSize.x/program.state.matSize.y} participantsStatus={program.state.participantsStatus}
            participants={team.state.participants.active} isPlaying={program.state.isPlaying}
            handleRightClick={openPartMenu} selectParticipant={program.selectParticipant} unselectParticipants={program.unselectParticipants}
            changeSelectionParticipant={program.changeSelectionParticipant} updatePartAvailable={program.updatePartAvailable}
            updateAvailable={program.state.matUpdateAvailable} setUpdateStatus={program.setMatUpdate}
        />
    ),[program.state.isPlaying, program.state.matUpdateAvailable])
    return (

        <Droppable type={"ParticipantAvatar"} onEndDrag={onEndDrag(program.setWaypoint, team.removeNotSettedParticipant)} droppableId="Mat">
        {
            (provided, snapshot) => 
            <div ref={provided.innerRef} id="showarea" style={{height: props.sizeY, position:"relative"}} onContextMenu={handleRightClick}>
                {memoMat}
                <ParticipantMenu anchorEl={anchorEl} setAnchorNull={setAnchorNull} 
                    setWaypointsForSelectedPart={program.setWaypointsForSelectedPart} openWaypointDialog={openWaypointDialog}/>
                <WaypointManagerDialog closeDialog={closeWaypointDialog} isOpen={isDialogOpen} 
                    getCommonWaypointCounts={program.getCommonWaypointsOfSelection} selectedParticipantIds={program.getSelectedParticipantIds()}
                    getSequencesNames={sequence.getSequencesNamesByCounts} removeWaypointOfParticipants={program.removeWaypointOfParticipants}/>
            </div>
        }
        </Droppable>

    )

};

export default withProgram(MatDroppable);

//<PositionHoldPopover anchorEl={anchorEl} handleClose={handlePopoverClose}/> 