
import React, {} from 'react';
import { withTheme, Avatar, makeStyles, Tooltip } from '@material-ui/core';
import SequenceSelectorList from '../SequenceSelectorList';
import RoleColor from 'data/Positions'



const useStyles = size => roleColor => isSelected => makeStyles((theme) => ({
    style: {
        width: theme.spacing(size),
        height: theme.spacing(size),
    },
    basic: {
        color: theme.progressActive,
        backgroundColor:   theme.progressPassive, //roleColor ? roleColor :
        border: isSelected ? "2px solid "+theme.progressActive : roleColor ? "2px solid "+roleColor : null
    },

}));

function ParticipantProfile (props){
    const {participant, isSelected, ...other} = props;

    const getColorByRole = (role) =>
    {
        return RoleColor[role];
    }
    const classes = useStyles(7)(getColorByRole(participant.positions[0]))(isSelected)();

    return (
        <Tooltip title={participant ? participant.name : ""} placement="top-start" arrow TransitionProps={{ timeout: 300 }} enterDelay={500}>
            <Avatar className={`${classes.style} ${classes.basic}`} >{participant ? participant.short : ""}</Avatar>
        </Tooltip>
    )
};

export default withTheme(ParticipantProfile);
