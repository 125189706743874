


import React from 'react';
import { CardContent, Typography, Card, Grid, IconButton, } from '@material-ui/core';
import { Draggable } from "react-beautiful-dnd";

import GroupIcon from '@material-ui/icons/Group';
import DeleteIcon from '@material-ui/icons/Delete';



export default function Participant(props) {

    const getStyle = (style, snapshot, draggableStyle) => {
        if (!snapshot.isDragging) return style;
        if (!snapshot.isDropAnimating) {
            return { ...style, ...draggableStyle };
        }

        return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.001s`
        };
    }

    const getItemStyle = () => (
        {
            width: 125,
            maxHeight: 150,
        })
    return (
        <Draggable key={props.participant.id} draggableId={props.participant.id} index={props.index}>
            {(provided, snapshot) => (
                <Grid item ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getStyle(getItemStyle(), snapshot, provided.draggableProps.style)}>
                    <Card style={{ position: "relative" }}>
                        {props.isRemovable ?
                            <IconButton name={props.participant.id} size="small" style={{ position: "absolute", display: "block", right: "0px" }} onClick={props.removeParticipant}>
                                <DeleteIcon />
                            </IconButton> : null
                        }
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {props.participant.short}
                            </Typography>
                            <div style={{ height: "30px" }}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {props.participant.name}
                                </Typography>
                            </div>
                        </CardContent>
                        {props.isActive ?
                            <div style={{ height: "40px", width: "100%", border: "1px solid rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <IconButton >
                                    <GroupIcon />
                                </IconButton>
                            </div> : null
                        }
                    </Card>
                </Grid>
            )}
        </Draggable>
    );
}

/*
<Tabs
              value={props.value}
              onChange={props.onChange}
              variant="fullWidth"
              aria-label="icon label tabs example"
              className={classes.root}
          >
              <Tab label="General" icon={<SettingsIcon />} />
              <Tab label="Team" icon={<GroupIcon />} />
              <Tab label="Sequence" icon={<TimelineIcon />} />
              <Tab label="Mat" icon={<PhotoSizeSelectSmallIcon />} />
              <Tab label="Music/BPM" icon={<MusicNoteIcon />} />
              <Tab label="Finish" icon={<DoneIcon />} />
          </Tabs>*/