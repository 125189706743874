import React, {useState} from 'react';
import MediaButton from './MediaButton';
import { Slider, Popper, Box, Fade, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';



function VolumeSlider (props)
{

    const [anchor, setAnchor] = useState(null);
    const [volume, setVolume] = useState(props.startVolume);
    const [isPopoverHover, setIsPopoverHover] = useState(false);
    
    const volumeIcon ="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z";
    
    const handleClose = (e) =>{
        if(!isPopoverHover)
            setAnchor(null);
    }
    const handleOpen = e =>{
        setAnchor(e.currentTarget);
    }
    const onChange = (e, value) => {
        setVolume(value);
        props.onChangeVolume(value/100);
    }

    const popoverEnter = () =>{
        setIsPopoverHover(true);
    }
    const popoverLeave = () =>{
        setIsPopoverHover(false);
        setAnchor(null);
    }
    const isOpen = Boolean(anchor);
    return (
    <div onMouseEnter={handleOpen} onMouseLeave={handleClose}>
        <MediaButton iconPath={volumeIcon} />
        <Popper
            open={isOpen}
            anchorEl={anchor}
            onClose={handleClose}
            transition 
            placement="bottom"
            onMouseEnter={popoverEnter} onMouseLeave={popoverLeave}
        >
        {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
                <Paper style={{paddingTop: 8, paddingBottom: 8, overflow: "hidden", margin: "5px 15px 15px"}}>   
                    <Slider
                        value={volume}
                        onChange={onChange}
                        style={{height: 100}}
                        orientation="vertical"
                    />
                </Paper>
            </Fade>
        )}
        </Popper>
    </div>)
}

export default VolumeSlider;