import { Container } from 'unstated-no-context-polyfill';
import {renameProperty, getNestedObjectRef, getNestedObjectRefSimple} from '../../lib/Utilities';

class SequenceContainer extends Container /* < SequenceState > */ {

    constructor(props = {}) {
        super();
        this.state = {
            visibleSequence: JSON.parse(JSON.stringify(props.sequence)),
            visibleHasGroups: Object.keys(props.sequence.sequences).length > 1,
            path: [],
        };
        this.sequence = JSON.parse(JSON.stringify(props.sequence))
    }
//
    getTopLevelSequences = () => {
        let result = [];
        Object.values(this.sequence.sequences).forEach(list =>
            list.forEach(seq => 
                result.push({
                    name: seq.name,
                    start: seq.startCount,
                    end: seq.startCount+ seq.counts
                }))
        )
        return result;
    }

    checkNewMaxCountIsValid = (counts) => {
        //BPM CHECK 
        let isValid = true;
        Object.values(this.sequence.sequences).forEach(seq =>{
            if(seq.length > 0)
            { 
                let lastSeq = seq[seq.length-1];
                if(lastSeq.startCount + lastSeq.counts > counts)
                    isValid = false;
            }
           
        })
        return isValid;
    }

    changeTopCount = (counts) => {
        this.sequence.counts = counts;
        let visibleSequence = this.sequence;
        this.setState({
            visibleSequence
        })
    }

    onChangeCount = (e) => {
        let [index, group, input] = e.target.name.split('$');
        let value = e.target.value;
        ///////////////// CHECK IF COUNT IS VALID /////////////////
        if (input === "counts") {
            value = parseInt(e.target.value)
            if (!this.isCountValid(value, this.state.visibleSequence, group, index))
                return false;
        }
        /////////////////////////// SAVE /////////////////////////////
        let visibleSequence = getNestedObjectRef(this.sequence, this.state.path, "sequences");
        visibleSequence.sequences[group][index][input] = value;
        this.recalculateStartCounts(visibleSequence,visibleSequence.startCount);
        this.setState({
            visibleSequence
        })
        return true;
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        //if (result.type === "SEQUENCE") {
        if(result.type.length > 0)
        {
            let visibleSequence = getNestedObjectRef(this.sequence, this.state.path, "sequences");
            visibleSequence.sequences[result.type] = this.reorder(
                this.state.visibleSequence.sequences[result.type],
                result.source.index,
                result.destination.index
            );
            this.recalculateStartCounts(visibleSequence, visibleSequence.startCount);

            this.setState({
                visibleSequence
            });
        }
    }
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };


    zoom = (e) => {
        let path = [...this.state.path];
        
        let [index, group] = e.currentTarget.name.split('$');
        index = parseInt(index);
        path.push({index, group});
        this.setState({
            path: path,
            visibleHasGroups: Object.keys(this.state.visibleSequence.sequences[group][index].sequences).length > 1,
            visibleSequence: this.state.visibleSequence.sequences[group][index]
        });
    }

    zoomOut = (e) => {
        let path = [...this.state.path];
        path.pop();
        let copyS = JSON.parse(JSON.stringify(this.sequence));
        const visibleSequence = getNestedObjectRef(copyS, path, "sequences");
        this.setState({
            path: path,
            visibleHasGroups: Object.keys(visibleSequence.sequences).length > 1,
            visibleSequence: visibleSequence
        });
    }


    addSequence = (groupName) => {
        let remainingCountSpace = this.getRemainingCountspace(this.state.visibleSequence, groupName);
        if (remainingCountSpace > 0)
        {
            let newSequence = {
                id: (Math.random()* Math.floor(1000000)).toString(),
                startCount: 0,
                counts: Math.floor(remainingCountSpace/24) * 8 > 7 ? Math.floor(remainingCountSpace/24) * 8 : remainingCountSpace,
                name: "",
                sequences: {"All": []},
            }
    
            let visibleSequence = getNestedObjectRef(this.sequence, this.state.path, "sequences");
            if (visibleSequence.sequences[groupName].length > 0)
            {
                let lastSeq = visibleSequence.sequences[groupName][visibleSequence.sequences[groupName].length-1];
                newSequence.startCount = lastSeq.startCount + lastSeq.counts;
            }
            else
                newSequence.startCount = visibleSequence.startCount;
            
            visibleSequence.sequences[groupName].push(newSequence);

            this.setState({
                visibleSequence
            })
        }
    }

    addGroup = () =>{
        let visibleSequence = getNestedObjectRef(this.sequence, this.state.path, "sequences");
        //temp
        let counter = 1;
        let name="Gruppe"
        let keys = Object.keys(visibleSequence.sequences);
        for(let i = 0; i< keys.length; i++)
        {
            if(keys[i] === name+counter)
            {
                counter++;
                i = 0;
            }
        }
        visibleSequence.sequences[name+counter] = [];
        this.setState({
            visibleSequence, 
            visibleHasGroups: true
        })
    }

    removeGroup = (e) => {
        if(this.state.visibleHasGroups)
        {
            let visibleSequence = getNestedObjectRef(this.sequence, this.state.path, "sequences");
            delete(visibleSequence.sequences[e.currentTarget.name]);
            this.setState({
                visibleSequence,
                visibleHasGroups: Object.keys(visibleSequence.sequences).length > 1
            });
           // visibleSequence.sequences.remove()
        }
    }

    renameGroup = async (oldName, newName) => {
        return new Promise( (resolve, rejects) => {
            if(oldName !== newName && newName !== "")
            {
                let visibleSequence = getNestedObjectRef(this.sequence, this.state.path, "sequences");
                visibleSequence.sequences = renameProperty(oldName, newName, visibleSequence.sequences);
                this.setState({
                    visibleSequence
                }, () => resolve());
            }
        });
    }


    removeSequence = (e) =>
    {
        let [index, group] = e.currentTarget.name.split('$');
        index = parseInt(index);
        let visibleSequence = getNestedObjectRef(this.sequence, this.state.path, "sequences");
        visibleSequence.sequences[group].splice(index, 1);
        this.recalculateStartCounts(visibleSequence, visibleSequence.startCount);
        this.setState({
            visibleSequence
        })
    }

    setVisibleSequenceToTop = () => {
        let visibleSequence = this.sequence;
        this.setState({
            visibleSequence,
            visibleHasGroups: Object.keys(visibleSequence.sequences).length > 1,
            path: []
        })
    }

    setTopSequenceName = (name) =>{
        let visibleSequence = this.sequence;
        visibleSequence.name = name;
        this.setState({
            visibleSequence
        })
    }

    isCountValid = (value, sequence, group, index) => {
        
        if (value < 1)
            return false;

        let changedSequence = sequence.sequences[group][index];
        let isIncrease = value > changedSequence.counts;
        if (isIncrease)
        {
            let space = this.getRemainingCountspace(sequence, group);
            if (space - (value - changedSequence.counts) < 0)
                return false;
        }
        else if (changedSequence.sequences !== undefined && Object.keys(changedSequence.sequences).length > 0) 
        {
            for(let k in changedSequence.sequences)
            {
                let totalChildCount = 0;
                changedSequence.sequences[k].forEach(seq => totalChildCount += seq.counts);
                if (totalChildCount > value)
                    return false;
            }
        }
        
        return true;
    }

    recalculateStartCounts = (sequence, preCount) => {
        let groups = Object.keys(sequence.sequences);
        let currentCount = preCount;
        sequence.startCount = currentCount;
        groups.forEach(g => {
            currentCount = preCount;
            sequence.sequences[g].forEach(seq =>
            {
                seq.startCount = currentCount;
                this.recalculateStartCounts(seq, currentCount);
                currentCount += seq.counts;
            })
        })
    }
    

    getRemainingCountspace =  (sequence, group) =>
    {
        let totalCounts = 0;
        sequence.sequences[group].forEach(seq => totalCounts += seq.counts)
        return sequence.counts - totalCounts;
    }
    getSequenceByCount = (count) => {
        let path = this.getSequencePathByCount(count);
        return getNestedObjectRef(this.sequence, this.state.path, "sequences");
    }
    getSequenceBySimplePath = path => 
    {
        return getNestedObjectRefSimple(this.sequence, path, "sequences");
    }

    getSequencePathByCount = counts =>{
        let count = counts;
        let path = [];
        
        this.findSequencePathByNearestStartCount(this.sequence.sequences, count, [], path)
        return path;
    }

    findSequencePathByNearestStartCount = (sequences, count, pathTemp, result) =>
    {
        let index;
        let keys = Object.keys(sequences);
        let path;
        keys.forEach(k => 
        {
            path = [...pathTemp];
            var seq = sequences[k];
            seq.forEach((s, i) => {
                if (s.startCount <= count)
                    index = i;
            })
            if (index === undefined)
            {
                result.push(path);
                return path;
            }

            path.push(k);
            path.push(index);
            if(seq[index].sequences && Object.keys(seq[index].sequences).length > 0)
            {
                path = this.findSequencePathByNearestStartCount(seq[index].sequences, count, path, result);
            }
            else
                result.push(path)

        });
        return path;
    }

    getSequencesNamesByCounts = (counts) =>{
        let result = [];
        result = this.findNearestSequencesNamesByCounts(this.sequence, counts, 0, result, "", 0, 0)[0];
        return result;
    }

    eightCountToOneCount = (count) => (count["8"] -1) * 8 + count["1"];

    findNearestSequencesNamesByCounts = (sequence, counts, countIndex, result, namePath, depth, nrGroups) =>{
        let keys = Object.keys(sequence.sequences);
        if(countIndex >= counts.length)
            return [result, countIndex];
        let count = counts[countIndex].count;
        keys.forEach((k, index) => 
        {
            let hasMultipleGroups = keys.length > 1;
            if (hasMultipleGroups && index==0)
                nrGroups++;

            var seq = sequence.sequences[k];
            let namePathTemp = namePath;
            if(depth > 0)
                namePathTemp += sequence.name + (hasMultipleGroups ?  "("+k+") - ": " - ");

            let seqFound =false;
            seq.forEach((s, i) => {
                if (s.startCount <= count && s.startCount+s.counts > count)
                {
                    seqFound = true;
                    if(seq[i].sequences && Object.keys(seq[i].sequences).length > 0)
                    {
                        [result, countIndex] = this.findNearestSequencesNamesByCounts(seq[i], counts, countIndex, result, namePathTemp, depth+1, nrGroups);
                        if(countIndex < counts.length)
                        {
                            count = counts[countIndex].count
                        }
                    }
                }
                  
            })
            if (!seqFound)
            {
                let countIndexTemp = countIndex;
                while(sequence.startCount <= count && sequence.startCount+sequence.counts >= count)
                {
                    if(nrGroups > 0 && result[countIndex])
                        result[countIndex] += " & " + namePath + sequence.name;
                    else
                        result.push(namePath + sequence.name)
                    countIndex++;
                    if(countIndex >= counts.length)
                        break;
                    count = counts[countIndex].count
                }
                if(nrGroups > 0)
                    countIndex = countIndexTemp;

                if(hasMultipleGroups && index == keys.length -1)
                    nrGroups--;
                return [result, countIndex];
            }
        });
        return [result, countIndex];
    }
}

export default SequenceContainer;