import React, {useState, useEffect} from 'react';
import withAuthorization from '../../router/WithAuthorization';
import { withFirebase } from '../../container/firebase';

import { Container } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import Team from './../../container/Team';
import AthletsTable from './elements/AthletsTable';
import TopBar, {TopBarSkeleton} from './elements/TopBar';
import LeftPanel from './elements/LeftPanel';
import Settings from './elements/Settings';

let data = [
    {name: "Jan Mayer", positions: ["Base", "Tumbler", "Flyer"], birthdate: new Date(1995, 7, 10), email: "Jan@gmail.com"},
    {name: "Nico Wenz", positions: ["Stumbler", "Flyer"], birthdate: new Date(1999, 6, 26), email: "Nico@gmail.com"},
    {name: "Elena Glaser", positions: ["Base", "Tumbler", "Flyer"], birthdate: new Date(2000, 3, 29), email: "Elena@gmail.com"}
  ];
const GENERALDATATEMPLATE = {
    name: "",
    level: "",
    stats: {}
}
let generalData = {...GENERALDATATEMPLATE};

function EditingWrapper (props) {

    const [selectedMenu, setSelectedMenu] = useState(0);
    const [dataLoaded, setDataLoaded] = useState(false);
    
    const getData = async () => {
        generalData = {...GENERALDATATEMPLATE};
        if(props.location.state.teamId && !!props.firebase.auth.currentUser)
        {
            data = await props.firebase.getAthletsOfTeam(props.location.state.teamId);
            generalData = await props.firebase.getTeamData(props.location.state.teamId);
            setDataLoaded(true);
        }
        else if(!!props.firebase.auth.currentUser)
        {
            data = [];
            generalData.id = await props.firebase.createNewTeam();
            setSelectedMenu(1);
            setDataLoaded(true);
        }
    }
    useEffect(() =>{
        if(!dataLoaded)
            getData();
    });

    const getCurrentMenu = () => {
        switch(selectedMenu)
        {
            case 0: return <AthletsTable />;
            case 1: return <Settings/>; 
            default: return <AthletsTable />;
        }

    }
    
    if(!dataLoaded)
        return (
            <div style={{height: "100vh"}}>
                <TopBarSkeleton/>
                <Container style={{height: "100%"}}>
                    <div style={{height: "100%", display:"flex", flexDirection: "row"}}>
                        <LeftPanel selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}/>
                        <div style={{height: "100%", background: "#263341", width: "100%"}}>
                            <Skeleton variant="rect" width={"100%"} height={"100%"} animation="wave"/>
                        </div>
                    </div>
                </Container>
            </div>
        );


    return (
        <Team.Provider initialState={{
            athlets: data,
            generalData: generalData
        }}>
            <div style={{height: "100vh"}}>
                <TopBar />
                <Container style={{height: "100%"}}>
                    <div style={{height: "100%", display:"flex", flexDirection: "row"}}>
                        <LeftPanel selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}/>
                        {getCurrentMenu()}
                    </div>
                </Container>
            </div>
        </Team.Provider>
    );
}


export default withAuthorization(authUser => !!authUser)(withFirebase(EditingWrapper));