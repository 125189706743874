import React, {} from 'react';
import Counter from './MediaControl/Counter';

import { Subscribe } from 'unstated-no-context-polyfill';
import ProgramContainer from '../../../../container/program/Program';
import TeamContainer from '../../../../container/program/Team';
import SequenceContainer from '../../../../container/program/Sequence';

import { Typography, Button } from '@material-ui/core';
import MediaButton from './MediaControl/MediaButton';
import VolumeSlider from './MediaControl/VolumeSlider';


export default function BottomPanelControl (props){
    const PlayIcon = "M8 5v14l11-7z";
    const StopIcon = "M6 19h4V5H6v14zm8-14v14h4V5h-4z";
    const NextIcon = "M6 6h2v12H6zm3.5 6l8.5 6V6z";
    const PreviousIcon ="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z";

    return (
        <Subscribe to={[SequenceContainer, TeamContainer, ProgramContainer]}>
        {
            (sequence, team, program) => (
            <div style={{ height: "10%", width: "60%", position: "absolute", top: 0, boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)", 
                        display: "flex", justifyContent: "space-around"}}>
                <div></div>
                <div style={{display: "flex", justifyContent: "space-around", alignItems: "center", width:"500px", height: "100%"}}>
                    <MediaButton onClick={program.onPlayPause} iconPath={program.state.isPlaying ? StopIcon : PlayIcon}/>
                    <div style={{display: "flex", justifyContent: "space-around", alignItems: "center", width:"300px", height: "100%"}}>
                        
                        <div style={{display: "flex", justifyContent: "space-around", alignItems: "center", width:"125px", height: "100%"}}>
                            <Counter width={"50px"} label={"8-count"} program={program} sequence={sequence} id="8"/>
                            <Typography>:</Typography>
                            <Counter width={"50px"} label={"count"} program={program} sequence={sequence} id="1"/>
                        </div>
                        
                        <VolumeSlider startVolume={program.startVolume * 100} onChangeVolume={program.setVolume}/>
                        
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                    <Button onClick={program.selectAllParticipants}>Select All</Button>
                </div>
            </div>
        )}
        </Subscribe>

)}
/* 
<MediaButton iconPath={NextIcon}/>
<MediaButton iconPath={PreviousIcon}/>
*/