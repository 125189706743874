import React, {useState} from 'react';
import MUIDataTable from "mui-datatables";

import { makeStyles, Chip, withTheme, IconButton } from '@material-ui/core/';
import FilterList, {CustomChip} from '../../customComponents/Filterlist';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from "@material-ui/icons/Add";

import AthletDialog from './AthletDialog';
import Positions from 'data/Positions';
import TeamContainer from 'container/Team';
import {calculateAge,sortAlphaNum} from 'lib/Utilities';

import {withFirebase} from 'container/firebase';

const getPositionColor = (position) =>{
    return Positions[position]
}

const useStyles = makeStyles((theme) => ({
    background:{
        height: "100vh",
    },
    table:{
        width: "100%"
    },
}));

const mappingLabelValue = (data) => ({
    "Name": data["name"],
    "Age": Object.prototype.toString.call(data["birthdate"]) === "[object Date]" && !isNaN(data["birthdate"].getTime()) ? calculateAge(data["birthdate"]) : "",
    "E-Mail": data["email"],
})
const getColumnsAndDataByObject = objects => {
    let data = [];
    objects.forEach((p) => {
        let row = [];
        let m = mappingLabelValue(p);
        m = {...m, short: p.short}
        Object.values(m).forEach( k=> {
            row.push(k)
        })
        data.push(row);
    })
    return data;
}

function AthletsTable(props) {
    const classes = useStyles();
    const [athletDialogData, setAthletDialogData] = useState([false, null]);
    let team = TeamContainer.useContainer();
    const openAthletDialog = (id) => () => {
        setAthletDialogData([true, id])
    }
    const closeAthletDialog = () => setAthletDialogData([false, null]);

    const deleteAthlets = (e) =>{
        let result = team.deleteAthlets(Object.keys(e.lookup))
        props.firebase.deleteAthletes(team.generalData.id)(result[0]);
        props.firebase.saveTeamData(result[1]);
    }
      
    const columns = [ 
        ...Object.keys(mappingLabelValue({})),
        { 
            name: 'Short',
            options: {
                filter: false,
                sortCompare: (order) =>{
                    return (s1, s2) => sortAlphaNum(s1.data, s2.data, order);
                }
            }
        },
        {
            name: 'Positions',
            options: {
                filter: true,
                filterType: 'multiselect',
                customBodyRenderLite: (dataIndex) => {
                    let value = team.athlets[dataIndex].positions;
                    return value.map( (val, key) => {
                        return <Chip
                            style={{color: getPositionColor(val),
                                borderColor: getPositionColor(val),
                                marginRight: props.theme.spacing(1)
                            }}
                            variant="outlined"
                            label={val} key={key} 
                        />;
                    });
                },
            }
        },
        {
            name: 'Edit',
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => (
                    <IconButton size={"small"} onClick={openAthletDialog(dataIndex)}>
                        <EditIcon />
                    </IconButton>
                )
            }
        },
    ];

       
    const options = {
        download:false,
        print:false,
        isRowSelectable:false,
        rowHover: true,
        tableBodyHeight:'80%',
        responsive: 'standard',
        sortFilterList: false,
      //  onRowClick:(e, meta) => {},
        onRowsDelete: deleteAthlets,
        customToolbar: () => (
            <IconButton className={classes.iconButton} onClick={openAthletDialog(null)}>
                <AddIcon className={classes.deleteIcon} />
            </IconButton>
        ),

    };

    return (
        <React.Fragment>
           <AthletDialog athlet={athletDialogData[1]!==null ? team.athlets[athletDialogData[1]]: null} isOpen={athletDialogData[0]} 
            handleClose={closeAthletDialog} saveAthlet={team.saveAthlet(athletDialogData[1])} teamId={team.generalData.id}/>
            <MUIDataTable
                className={classes.table}
                title={"Athlets"}
                data={getColumnsAndDataByObject(team.athlets)}
                columns={columns}
                options={options}
                components={{
                    TableFilterList: FilterList,
                }}
            />
        </React.Fragment>
    );
}


export default withTheme(withFirebase(AthletsTable));