import { Container } from 'unstated-no-context-polyfill';

import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import KeyframePlugin from '../../lib/WavesurferKeyframe';

import {  getNearestWaypointOfIdsIncludeCurrent, getNearestWaypointOfId, getTimeByCountDuration,getCountByTime, 
    loopThroughAllWaypoints,getMaxCountByProgram } from '../../lib/ProgramCalculations';

import { createSilentAudio } from 'lib/AudioCreator'

class ProgramContainer extends Container {

    constructor(props = {}) {
        super();
        this.state = {
            id: props.general.id,
            name: props.general.name,
            matSize: {x: 12, y: 12},
            maxCount: props.general.maxCount,
            //--MusicData--
            musicUrl: props.general.musicUrl,
            musicData: null,
            bpm: props.general.bpm,
            isChant: false,
            containsChant: props.general.containsChant,
            chantTime: props.general.chantTime,
            musicOffset: props.general.musicOffset,
            duration: props.general.duration ? props.general.duration : 0,
            oneCountStart: 1,
            //-----------
            wavesurverReady: false,
            isPlaying: false,
            matUpdateAvailable: false,
            currentCount: 1+getCountByTime(0, props.general.bpm,props.general.musicOffset)[0],
            sequenceProgress: 0,
            path: [["0"]],
            waypoints: JSON.parse(JSON.stringify(props.waypoints)),
            participantsStatus: this.getCurrentParticipantsStatus(0, 0, props.participantsStatusIds, props.waypoints, props.general.maxCount, 
                props.general.bpm, props.general.musicOffset, 1, props.general.chantTime, props.general.containsChant, true),
            /*
            {
                "SEy5UNXVP7WEC0YtgQxZ": {endPos: {x: 0.8, y: 0.1}, startPos: {x: 0.8, y: 0.1}, prevCount: 1, nextCount: 1, duration: 3.1, isSelected: false},
                "FC": {endPos: {x: 0.1, y: 0.1}, startPos: {x: 0.1, y: 0.1}, prevCount: 1, nextCount: 1, duration: 3.1, isSelected: false},
            },*/
        };
        this.forceMatUpdate = false;
        this.startVolume = 0.2;
        this.wavesurfer = {};
        this.lastTimePosition = 0; //to avoid circle of time- & countrecalculation
        this.updatePartAvailable ={};
        
    }

    getProgramGeneralData = () =>({
        name: this.state.name,
        bpm: this.state.bpm,
        containsChant: this.state.isChant,
        chantTime: this.state.chantTime,
        musicOffset: this.state.musicOffset,
        oneCountStart: this.state.oneCountStart,
        duration: this.state.duration,
        matSize: this.state.matSize,
        musicUrl: this.state.musicUrl,
        maxCount: this.state.maxCount,
    });

    setProgramName = (name) => {
        this.setState({name});
    }

    isWaypointSet = eight => Boolean(this.state.waypoints[eight]);
    
    removeParticipantFromMat = (id) => {
        return new Promise(resolve => {
            let waypoints =  JSON.parse(JSON.stringify(this.state.waypoints));
            let participantsStatus =  JSON.parse(JSON.stringify(this.state.participantsStatus));

            Object.keys(waypoints).forEach(count =>{if(waypoints[count])delete waypoints[count][id]});

            delete participantsStatus[id];
            this.setState({waypoints, participantsStatus, matUpdateAvailable: true}, () => resolve())
        });
    }



    ///////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////// WAYPOINTS /////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////

    removeWaypointOfParticipants = (count, ids) => {
        let participantsStatus = JSON.parse(JSON.stringify(this.state.participantsStatus));
        let waypoints = JSON.parse(JSON.stringify(this.state.waypoints));
        ids.forEach(id =>{
            if(participantsStatus[id].nextCount === count || participantsStatus[id].prevCount )
            {
                let wps = getNearestWaypointOfId(id, this.state.waypoints)(count, this.state.maxCount);
                participantsStatus[id].nextCount = wps.next ? wps.next.count : null;
                participantsStatus[id].prevCount = wps.prev ? wps.prev.count : null;
            }
            if(waypoints[count][id].prevEqWaypoint)
            {
                let prev = waypoints[count][id].prevEqWaypoint;
                waypoints[prev][id].nextEqWaypoint = null;
            }
            if(waypoints[count][id].nextEqWaypoint)
            {
                let next = waypoints[count][id].nextEqWaypoint;
                waypoints[next][id].prevEqWaypoint = null;
            }
            delete waypoints[count][id]
        })
        this.setState({waypoints, participantsStatus});
    }

    checkIfPrevNextWaypointSamePos = (wps, pos) =>{
        let next = !wps.next ||  wps.next.x !== pos.x && wps.next.y !== pos.y ? null : wps.next;
        let prev = !wps.prev ||  wps.prev.x !== pos.x && wps.prev.y !== pos.y ? null : wps.prev;
        return {next, prev}
    }

    setWaypoint = (participantData) =>{
        //Waypoints
        let waypoints = JSON.parse(JSON.stringify(this.state.waypoints));
        let count = this.state.currentCount;
        
        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        ///////////////////////////////// TEMP ///////////////////////////////////
        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        if(this.state.isChant || count <=  0)
            count = 1
        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

        
        if(!waypoints[count])
            waypoints[count] = {};
        
        //ParticipantStatus
        let participantsStatus = JSON.parse(JSON.stringify(this.state.participantsStatus));
        let participantPos = {x: participantData.relativePosition.x, y: participantData.relativePosition.y};
        if(!participantsStatus[participantData.id])
            participantsStatus[participantData.id] = {}

        //Check if nearby equal Waypoints. If surrounded -> unconnect them, if just one -> connect
        let wps = getNearestWaypointOfId(participantData.id, this.state.waypoints)(count, this.state.maxCount);
        let nearEqWpCounts = this.checkIfPrevNextWaypointSamePos(wps, participantsStatus[participantData.id].endPos);
        
        if(nearEqWpCounts.next && nearEqWpCounts.prev)
        {
            waypoints[nearEqWpCounts.next.count][participantData.id].prevEqWaypoint= null;
            waypoints[nearEqWpCounts.prev.count][participantData.id].nextEqWaypoint= null;
        }


        participantsStatus[participantData.id].prevCount = count;
        participantsStatus[participantData.id].nextCount = nearEqWpCounts.next;
        participantsStatus[participantData.id].endPos = participantPos;
        participantsStatus[participantData.id].startPos = participantPos;
        waypoints[count][participantData.id] = {
            ...participantData.relativePosition,
            nextEqWaypoint: null, 
            prevEqWaypoint:  null
        };
        this.updatePartAvailable[participantData.id] = true;
        console.log(waypoints)
        this.setState({participantsStatus, waypoints,matUpdateAvailable: true}, () => this.setKeyframes(this.getSelectedParticipantIds()));
    }
    setWaypointsForSelectedPart = () =>{
        const ids = this.getSelectedParticipantIds();
        this.setWaypointsMultiplePart(ids);
    }
    setWaypointsMultiplePart = (ids) =>{
        
        
        let participantsStatus = JSON.parse(JSON.stringify(this.state.participantsStatus));
        let waypoints = JSON.parse(JSON.stringify(this.state.waypoints));
        let cc = this.state.currentCount;
        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        ///////////////////////////////// TEMP ///////////////////////////////////
        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        if(cc <= 0)
            return;
        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        

        if(!waypoints[cc])
            waypoints[cc] = {};

        ids.forEach(id =>{
            let closestWps = getNearestWaypointOfId(id, this.state.waypoints)(cc, this.state.maxCount);
            let {prev, next} = this.checkIfPrevNextWaypointSamePos(closestWps, participantsStatus[id].startPos);

            let prevEqCount = null;
            let nextEqCount = null;
            let position = {...participantsStatus[id].startPos}
            if(prev != null)
            {
                if(prev.count === cc)
                    return;
                prevEqCount = prev.count;
                if(waypoints[prev.count][id].prevEqWaypoint)
                {
                    prevEqCount = waypoints[prev.count][id].prevEqWaypoint;
                    delete waypoints[prev.count][id];
                }
                if(next != null)
                    delete waypoints[next.count][id];

                waypoints[prevEqCount][id].nextEqWaypoint = cc;
            }
            else if(next != null)
            {
                nextEqCount = next.count;
                if(waypoints[next.count][id].nextEqWaypoint)
                {
                    nextEqCount = waypoints[next.count][id].nextEqWaypoint;
                    delete waypoints[next.count][id];
                }
                waypoints[nextEqCount][id].prevEqWaypoint = cc;
            }
            else if(closestWps.prev)//If participant has moved, set him back to last position => so part waits
            {
                if(closestWps.prev.count === cc)
                    return;
                prevEqCount = closestWps.prev.count;
                if(waypoints[prevEqCount][id].prevEqWaypoint)
                {
                    let toDeleteCount = prevEqCount;
                    prevEqCount = waypoints[toDeleteCount][id].prevEqWaypoint;
                    delete waypoints[toDeleteCount][id];
                }
                waypoints[prevEqCount][id].nextEqWaypoint = cc;
                position = {...waypoints[prevEqCount][id]};
                participantsStatus[id].startPos = {x: position.x, y: position.y};
            }
           // waypoints[cc["8"]][cc["1"]][id] = {...this.state.participantsStatus[id].position, prevEqWaypoint: prevEqCount, nextEqWaypoint: null}
           waypoints[cc][id] = {x: position.x, y: position.y, prevEqWaypoint: prevEqCount, nextEqWaypoint: nextEqCount}
        })
        console.log(waypoints)
        this.setState({participantsStatus, waypoints, matUpdateAvailable: true}, () =>{this.setKeyframes(ids)});
    }
    getCommonWaypointsOfSelection = (ids) =>{
        if(ids.length == 0)
            return [];
        const containsAllIds = (waypoints, count) =>{
            let found = 0;
            let areAllWaiting = true;
            Object.keys(waypoints).forEach(id => {
                if(ids.includes(id))
                {
                    found++;
                    if(!waypoints[id].nextEqWaypoint)
                        areAllWaiting = false;
                }
            });
            return found==ids.length ? {count, areAllWaiting} : null;
        }

        return loopThroughAllWaypoints(this.state.waypoints, this.state.maxCount)(containsAllIds);
    }
    ///////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////// SELECTION /////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////

    selectParticipant= id => {
        let participantsStatus = JSON.parse(JSON.stringify(this.state.participantsStatus));
        participantsStatus[id].isSelected = true;
        this.setKeyframes(this.getSelectedParticipantIds(participantsStatus));
        this.setState({participantsStatus, matUpdateAvailable: true});
    }
    selectAllParticipants = () =>{
        let participantsStatus = JSON.parse(JSON.stringify(this.state.participantsStatus));
        Object.keys(participantsStatus).forEach(id => {
            participantsStatus[id].isSelected = true;
        });
        this.setKeyframes(this.getSelectedParticipantIds(participantsStatus));
        this.setState({participantsStatus, matUpdateAvailable: true});
    }

    changeSelectionParticipant = id => {

        let participantsStatus = JSON.parse(JSON.stringify(this.state.participantsStatus));
        participantsStatus[id].isSelected = !participantsStatus[id].isSelected;
        let selectedIds = this.getSelectedParticipantIds(participantsStatus);
        if(selectedIds != 0)
            this.setKeyframes(selectedIds);
        else
            this.wavesurfer.clearKeyframes();

        this.setState({participantsStatus, matUpdateAvailable: true}, () =>{});
    }
    unselectParticipants = () =>{
        this.wavesurfer.clearKeyframes();
        let participantsStatus = this.setParticipantsStatusUnselected();
        this.setState({participantsStatus, matUpdateAvailable: true});
    }
    setParticipantsStatusUnselected = () =>{
        let participantsStatus = JSON.parse(JSON.stringify(this.state.participantsStatus));
        Object.keys(participantsStatus).forEach(k=>{
            participantsStatus[k].isSelected = false;
        })
        return participantsStatus
    }
    getSelectedParticipantIds = (data) =>{
        const ps = data ? data : this.state.participantsStatus;
        let ids =[];
        Object.keys(ps).forEach(id =>{
            if(ps[id].isSelected)
                ids.push(id);
        })
        return ids;
    }

    ///////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////// Participants Status ////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////

    getCurrentParticipantsStatus = (count, time, ids, waypoints = this.state.waypoints, maxCount = this.state.maxCount,bpm=this.state.bpm, musicOffset=this.state.musicOffset, oneCountStart= this.state.oneCountStart,
    chantTime= this.state.chantTime, containsChant=this.state.containsChant, isInitial=false) => {
        let participantsStatus = isInitial ? {} : JSON.parse(JSON.stringify(this.state.participantsStatus));
        let idsCopy = [...ids]
        let partWaypoints = getNearestWaypointOfIdsIncludeCurrent(idsCopy, waypoints)(count, maxCount);
        ids.forEach(id => {
            if(!participantsStatus[id])
                participantsStatus[id] = {};
            if(partWaypoints.prev && partWaypoints.prev[id] &&  partWaypoints.next && partWaypoints.next[id])
            {
                const prevCount = partWaypoints.prev[id].count;
                const nextCount = partWaypoints.next[id].count;
                let durationCounts = nextCount - count;
                let duration = getTimeByCountDuration(durationCounts, bpm);
                let maxTimespan = getTimeByCountDuration(nextCount, bpm) - getTimeByCountDuration(prevCount, bpm);
                let progress = (time- getTimeByCountDuration(prevCount-oneCountStart - (containsChant?(chantTime.chantEnd - chantTime.chantStart):0), bpm, musicOffset))/maxTimespan;
                
                let newStatus = {
                    duration,  
                    nextCount,
                    prevCount,
                    endPos: {
                        x: partWaypoints.next[id].x,
                        y: partWaypoints.next[id].y,
                    },
                    startPos: {
                        x: partWaypoints.prev[id].x + (partWaypoints.next[id].x - partWaypoints.prev[id].x) * progress,
                        y: partWaypoints.prev[id].y + (partWaypoints.next[id].y - partWaypoints.prev[id].y) * progress,
                    }
                }

                participantsStatus[id] = Object.assign(participantsStatus[id], newStatus);

            }
            else if(partWaypoints.prev && partWaypoints.prev[id])
            {
                participantsStatus[id].prevCount = partWaypoints.prev[id].count;
                participantsStatus[id].nextCount = null;
                participantsStatus[id].startPos = {x: partWaypoints.prev[id].x, y: partWaypoints.prev[id].y};
                participantsStatus[id].endPos = {x: partWaypoints.prev[id].x, y: partWaypoints.prev[id].y};
                participantsStatus[id].duration = 0;
            }
            else if(partWaypoints.next && partWaypoints.next[id])
            {
                participantsStatus[id].prevCount = null;
                participantsStatus[id].nextCount = partWaypoints.next[id].count;
                participantsStatus[id].startPos = {x: partWaypoints.next[id].x, y: partWaypoints.next[id].y}
                participantsStatus[id].endPos = {x: partWaypoints.next[id].x, y: partWaypoints.next[id].y}
                participantsStatus[id].duration = 0;
            }
        })
        return participantsStatus;
    }

    /*searchNearestWaypointsOfParticipantIds = (count, ids) =>{
        let idsCopy = [...ids];
        let nearestWps = getNearestWaypointOfIds(idsCopy, this.state.waypoints)(count, this.state.maxCount);
        return {prevWp: nearestWps.prev, nextWp: nearestWps.next};
    }*/


    setCountByMTime = (time, forceUpdate) =>{
        let [currentCount, timeDifference] = getCountByTime(time, this.state.bpm, this.state.musicOffset);
        currentCount =this.state.oneCountStart+currentCount;
        this.updateMat(currentCount, time, forceUpdate);
    }

    setMTimeByCount = (currentCount) =>{
        let time = this.setMusicPositon(currentCount);
        this.updateMat(currentCount, time, true);
    }

    updateMat = (currentCount, time, forceUpdate = false) =>{
        let idsToUpdate = [];
        if(currentCount !== this.state.currentCount|| forceUpdate)
        {
            if(currentCount <= 0)
            {
                this.setState({currentCount})
                return;
            }
            Object.keys(this.state.participantsStatus).some(id => {
                const ps = this.state.participantsStatus[id];
                if(ps.nextCount && ps.nextCount<=currentCount ||
                    ps.prevCount && !(ps.prevCount<currentCount) ||
                    forceUpdate) 
                {
                    this.updatePartAvailable[id] = true;
                    idsToUpdate.push(id)
                }
            })
            if(idsToUpdate.length > 0)
            {
                let participantsStatus = this.getCurrentParticipantsStatus(currentCount,time, idsToUpdate);
                this.setState({currentCount, participantsStatus, path: this.getSequencePathByCount(currentCount), matUpdateAvailable: true});
                return;
            }
            this.setState({currentCount, path: this.getSequencePathByCount(currentCount)})
        }
    }

    setMatUpdate = state => {
        this.setState({matUpdateAvailable: state});
    }


    ///////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////// COUNT ETC /////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////

    onIncreaseCount = (e) => {
        var currentCount = this.state.currentCount;
        currentCount += parseInt(e.currentTarget.id);
        this.setMTimeByCount(currentCount)
        return currentCount;
    }
    onDecreaseCount = (e) => {
        var currentCount = this.state.currentCount;
        currentCount -= parseInt(e.currentTarget.id);
        if(currentCount < 1)
            return;
        this.setMTimeByCount(currentCount);
        return currentCount;
    }

    
    setPath = (path) => {
        this.setState({path});
    }

    setMusicPositon = (count) =>{
        if(this.state.wavesurverReady)
        {
            let position = getTimeByCountDuration(count - this.state.oneCountStart,this.state.bpm, this.state.musicOffset);
            this.lastTimePosition = position;
            position = position < 0 ? 0 : position;
            this.wavesurfer.setCurrentTime(position);
            
            return position;
        }
        return 0;
    }

    onPlayPause = () =>{
        if(this.state.wavesurverReady)
        {
            if(this.state.isPlaying)
                this.wavesurfer.pause();
            else
                this.wavesurfer.play();
            this.setState({isPlaying: !this.state.isPlaying})
        }
    }
    setIsPlaying = (isPlaying) => {
        this.setState({isPlaying})
    };

    getCounts = () =>{
        return this.state.currentCount;
    }
    getExpandedIds = () => {
        let result = [];
        for(let i = 0; i < this.state.path.length; i++)
        {
            let path = [...this.state.path[i]]
            let count = path.length;
            for(let ii = 0; ii < count; ii++)
            {
                result.push(path.join("$"))
                path.pop();
            }
        }
        return result;
    }

    getPaths = () =>{
        let paths = [];
        this.state.path.forEach(p=> paths.push(p.join('$')))
        return paths;
    }


    ////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////// MUSIC DATA /////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////

    clearTempData = () =>{
        this.wavesurfer.empty();
        this.setCountByMTime(0, true, true);
        this.setState({isPlaying: false})
    }
   
    setNewMusicData = (data) =>{
        this.setState(data);
    }
    getMusicData = () =>{
       return{
            bpm: this.state.bpm,
            maxCount: this.state.maxCount,
            chantTime: this.state.chantTime,
            musicOffset: this.state.musicOffset,
            containsChant: this.state.containsChant,
            chantTime: this.state.chantTime,
            countStart:  this.state.countStart,
            duration: this.state.duration,
        }
    }
    setBuffer = (buffer) =>{
        
        this.musicBuffer = buffer;
        if(this.state.wavesurverReady)
        {
            this.setState({wavesurverReady: false})
            this.wavesurfer.empty();
            //reload via server? 
        }
    }

    setMatSize = (size) =>{
        this.setState({matSize: Object.assign(this.state.matSize,size)})
    }

    ////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////// WAVESURFER /////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////

    setKeyframes = (ids) =>{
        if(ids.length == 0)
            return;
        
        const containsAllIds = (waypoints, count) =>{
            let found = 0;
            Object.keys(waypoints).forEach(id => {
                if(ids.includes(id))
                    found++;
            });
            return found==ids.length ?
            {
                pos: getTimeByCountDuration(count - this.state.oneCountStart, this.state.bpm,this.state.musicOffset), 
                color:"#fe6b8b"
            }: null;
        }
        let keyframes = loopThroughAllWaypoints(this.state.waypoints, this.state.maxCount)(containsAllIds);
        this.wavesurfer.setKeyframes(keyframes);
    }
    setVolume= (value) =>{
        if(this.state.wavesurverReady)
        {
            this.wavesurfer.setVolume(value*0.5);
            this.startVolume = value;
        }
    }
    createWaveForm = (getSequencePathByCount, sequences) => (waveformRef, theme) =>{
        let regions = [];
        sequences.forEach((seq,i) => regions.push({
            start: getTimeByCountDuration(seq.start - this.state.oneCountStart,this.state.bpm, this.state.musicOffset),
            end: getTimeByCountDuration(seq.end - this.state.oneCountStart,this.state.bpm, this.state.musicOffset),
            loop: false,
            drag: false,
            resize: false,
            color: 'rgba('+255+', '+(i%2==0?255:0 )+','+(i%2==0?255:0 )+',0.1)',
            attributes: { label: seq.name }
        }));
        
        this.wavesurfer = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: theme.progressPassive,
            progressColor: theme.progressActive,
            height: document.getElementById('waveFormParent').clientHeight,
            barWidth: 3,
            responsive: true,
            plugins: [
                RegionsPlugin.create({regions}),
                KeyframePlugin.create({}),
            ],
        });

        this.getSequencePathByCount = getSequencePathByCount;
        
        if (this.state.musicUrl=== "")
            this.wavesurfer.loadBlob(createSilentAudio(this.state.duration,44100));
        else
            this.wavesurfer.load( this.state.musicUrl);
        
        this.wavesurfer.setVolume(this.startVolume*0.5);
        this.wavesurfer.on('ready', () => {
            let wavesurverReady = true;
            this.setState({wavesurverReady})
        });
        this.wavesurfer.on('seek', (position) => {
            var currentTime = position * this.wavesurfer.getDuration();
            if(Math.pow(this.lastTimePosition-currentTime, 2) > 0.01) //ignore second trigger through manual time-change
                this.setCountByMTime(currentTime, true);
        });

        this.wavesurfer.on('audioprocess', time => {
            this.setCountByMTime(time);
        })
    }
}

export default ProgramContainer;


/*
        this.wavesurfer.addRegion({start: 0,
            end: 20,
            drag: false,
            color: "rgba(100, 0 ,0, 0.5)"});*/