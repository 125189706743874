

class MusicCalculater
{

    static  getBpm = async (data, sampleRate, duration) =>
    {
        var OfflineContext = window.OfflineAudioContext || window.webkitOfflineAudioContext;
        var offlineContext = new OfflineContext(2, duration * sampleRate, sampleRate);
        return new Promise(resolve => {

          offlineContext.decodeAudioData(data, (buffer) => {
            // Create buffer source
            var source = offlineContext.createBufferSource();
            source.buffer = buffer;

            // Beats, or kicks, generally occur around the 100 to 150 hz range.
            // Below this is often the bassline.  So let's focus just on that.

            // First a lowpass to remove most of the song.

            var lowpass = offlineContext.createBiquadFilter();
            lowpass.type = "lowpass";
            lowpass.frequency.value = 150;
            lowpass.Q.value = 1;

            // Run the output of the source through the low pass.

            source.connect(lowpass);

            // Now a highpass to remove the bassline.

            var highpass = offlineContext.createBiquadFilter();
            highpass.type = "highpass";
            highpass.frequency.value = 100;
            highpass.Q.value = 1;

            // Run the output of the lowpass through the highpass.

            lowpass.connect(highpass);

            // Run the output of the highpass through our offline context.

            highpass.connect(offlineContext.destination);

            // Start the source, and render the output into the offline conext.

            source.start(0);
            offlineContext.startRendering();
          });
          offlineContext.oncomplete = (e) => {
            var buffer = e.renderedBuffer;
            var peaks = this.getPeaks([buffer.getChannelData(0), buffer.getChannelData(1)], sampleRate);
            var groups = this.getIntervals(peaks, sampleRate);
          // var silenceSeq = this.getSilenceSequence(buffer.getChannelData(0), sampleRate);

            var top = groups.sort(function(intA, intB) {
              return intB.count - intA.count;
            }).splice(0, 1);
            resolve(top[0].tempo);
          }
        })
    }
    static getSilenceSequence(data, sampleRate)
    {
      var cheerSeq = [0,0];
      let durations = [];
      let durationSeq = 0;
      for (var i = 0; i < data.length-1; i++) {

        if (data[i] === 0 && data[i+1] === 0)
        {
          durationSeq++;
        }
        else
        {
          if (durationSeq > 0)
          {
            durations[durations.length] = [(i - durationSeq-1)/sampleRate, (i-1)/sampleRate];
          }
          durationSeq = 0;
        }
      }
      cheerSeq = durations.sort(function(a, b) {
        return (b[1]-b[0]) - (a[1]-a[0]) ;
      }).slice(0,1)[0];
      return cheerSeq;
    }
    static getPeaks(data, sampleRate) {
        // What we're going to do here, is to divide up our audio into parts.
      
        // We will then identify, for each part, what the loudest sample is in that
        // part.
      
        // It's implied that that sample would represent the most likely 'beat'
        // within that part.
      
        // Each part is 0.5 seconds long - or 22,050 samples.
      
        // This will give us 60 'beats' - we will only take the loudest half of
        // those.
      
        // This will allow us to ignore breaks, and allow us to address tracks with
        // a BPM below 120.
      

        var partSize = sampleRate/2,
            parts = data[0].length / partSize,
            peaks = [];
        for (var i = 0; i < parts; i++) {
          var max = null;
          for (var j = i * partSize; j < (i + 1) * partSize; j++) {
            var volume = Math.max(Math.abs(data[0][j]), Math.abs(data[1][j]));
            if (!max || (volume > max.volume)) {
              max = {
                position: j,
                volume: volume
              };
            }
          }
          peaks.push(max);
        }
      
        // We then sort the peaks according to volume...
      
        peaks.sort(function(a, b) {
          return b.volume - a.volume;
        });
      
        // ...take the loundest half of those...
      
        peaks = peaks.splice(0, peaks.length * 0.5);
      
        // ...and re-sort it back based on position.
      
        peaks.sort(function(a, b) {
          return a.position - b.position;
        });
      
        return peaks;
    }


    static getIntervals = (peaks, sampleRate) => {

        // What we now do is get all of our peaks, and then measure the distance to
        // other peaks, to create intervals.  Then based on the distance between
        // those peaks (the distance of the intervals) we can calculate the BPM of
        // that particular interval.
      
        // The interval that is seen the most should have the BPM that corresponds
        // to the track itself.
      
        var groups = [];
      
        peaks.forEach(function(peak, index) {
          for (var i = 1; (index + i) < peaks.length && i < 10; i++) {
            var group = {
              tempo: (60 * sampleRate) / (peaks[index + i].position - peak.position),
              count: 1
            };
      
            while (group.tempo < 90) {
              group.tempo *= 2;
            }
      
            while (group.tempo > 180) {
              group.tempo /= 2;
            }
      
            group.tempo = Math.round(group.tempo);
      
            if (!(groups.some(interval =>  (interval.tempo === group.tempo ? interval.count++ : 0)
            ))) {
              groups.push(group);
            }
          }
        });
        return groups;
      }
}

export default MusicCalculater;