import React from 'react';

import { TextField, Grid, Typography } from '@material-ui/core';
//import ProgramContainer from '../../container/Program';
import { Subscribe } from 'unstated-no-context-polyfill';
import ProgramContainer from '../../container/program/Program';
import TeamContainer from '../../container/program/Team';
import SequenceContainer from '../../container/program/Sequence';


class GeneralSettings extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            success: null
        } 
    }
//value={} onChange={} 

    changeMatSize = (changeSize) => (e) =>{
        let size ={};
        size[e.currentTarget.name] = e.currentTarget.value;
        changeSize(size);
    }
    changeProgramName = (setProgramState, setSequenceState) => (e) => {
        setSequenceState(e.currentTarget.value);
        setProgramState(e.currentTarget.value);
    }

    render() {
        return (
            <Subscribe to={[SequenceContainer, TeamContainer, ProgramContainer]}>
            {
                (sequence, team, program) => (
                    <div style= {{height: "100%", width: "100vw", alignItems: "center", display: "flex", justifyContent: "center", position: "absolute"}}>
                        <form style={{display: "flex",alignItems: "center", justifyContent: "center"}}>
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <TextField fullWidth margin="dense" label="Name" value={sequence.sequence.name} 
                                    onChange={this.changeProgramName(program.setProgramName,sequence.setTopSequenceName)} variant="outlined"/>
                                </Grid>
                                <Grid item xs={10}/>
                                <Grid item xs={10}/>
                                <Grid item xs={10}><Typography style={{marginLeft: 25}}>{"Matsize"}</Typography></Grid>
                                <Grid item xs={5}>
                                    <TextField fullWidth margin="dense" value={program.state.matSize.x} onChange={this.changeMatSize(program.setMatSize)}
                                    label="Length" name={"x"} variant="outlined" type="number"/>
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField fullWidth margin="dense" value={program.state.matSize.y} onChange={this.changeMatSize(program.setMatSize)}
                                    label="Width" name={"y"}  variant="outlined" type="number"/>
                                </Grid>
                            </Grid>
                        </form>
                    </div>)
            }
            </Subscribe>
        );

    }
}

export default GeneralSettings;