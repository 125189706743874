import React, { useState } from 'react';
import { makeStyles, Container, Typography, Box, IconButton, Button } from '@material-ui/core/';
import { Link } from "react-router-dom";

import * as ROUTES from './../../router/routes';
import { withRouter } from 'react-router-dom';
import MenuContainer from '../../container/Menu';

import { Subscribe } from 'unstated-no-context-polyfill';
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import SportsIcon from '@material-ui/icons/Sports';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    menuButton: {
        width: "60%",
        margin: theme.spacing(2),
        padding: theme.spacing(4),
    }
}));


function MenuButton(props) {
    const classes = useStyles();
    return <Button size="large" className={classes.menuButton} variant="contained" startIcon={props.icon} disabled={props.disabled} onClick={props.onClick}>{props.label}</Button>
}

function ProgramMenu(props) {
    const classes = useStyles();

    return (
        <Subscribe to={[MenuContainer]}>
            {
                menu => (
                    <Container className={classes.container}>
                        <MenuButton icon={<VisibilityIcon />} label={"View"} disabled />
                        <MenuButton icon={<AccessibleForwardIcon />} label={"Trainingsmodus"} disabled />
                        <MenuButton icon={<ShareIcon />} label={"Share"} disabled />
                        <MenuButton icon={<ImportExportIcon />} label={"Export"} disabled />
                        <MenuButton icon={<EditIcon />} label={"Edit"} onClick={() => props.history.push(ROUTES.EDITING, { programId: menu.getSelectedProgram().id })} />
                    </Container>
                )
            }
        </Subscribe>
    );
}
export default withRouter(ProgramMenu);