

const removeArrayElement = (array, element) =>
{
    let newArray=[];
    array.forEach(el =>{
        if(el != element)
            newArray.push(el);
    })
    return newArray;
}

const calculateAge = (birthdate) => {
    const cur = new Date();
    const diff = cur-birthdate;
    return Math.floor(diff/31557600000); //1000*60*60*24*365.25
}


const asyncForEach = async (array, callback) =>{
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

const renameProperty = (oldKey, newKey, obj) => {
    const changedKeyMap = {};
    changedKeyMap[oldKey] = newKey;

    let keys = Object.keys(obj);
    const result = keys.reduce((acc, val) => {
        let content = obj[val];
        if (!!changedKeyMap[val]) 
            val = changedKeyMap[val];
        
        acc[val] = content;
        return acc;
    }, {});

    return result;
}


const getFirstElementOfObj = (obj) => {
    let first;
    for (let key in obj)
    {
        first = obj[key];
        break;
    }
    return  first
}
const getNestedObjectRef = (nestedObject, attributes, arr) => {
    let temp = nestedObject;
    if (attributes.length > 0)
        attributes.forEach(attr => arr ? temp = temp[arr][attr.group][attr.index] : temp = temp[attr.group])

    return temp;
}

const getNestedObjectRefSimple = (nestedObject, attributes, arr) => {
    let temp = nestedObject;
    for(let i = 0; i < attributes.length-1; i += 2)
    {
        temp = arr ? temp[arr][attributes[i]][attributes[i+1]] : temp[attributes[i]][attributes[i+1]]
    }
    return temp;
}

const round = (wert, dez)  => {
    if (!wert) return 0;
    if (!dez) dez=0;

    var umrechnungsfaktor = Math.pow(10,dez);

    return Math.round(wert * umrechnungsfaktor) / umrechnungsfaktor;
}


const getRandomInt = (min, max)  => { 
    return Math.floor(Math.random() * (max - min + 1) + min);
}


var reA = /[^a-zA-Z]/g;
var reN = /[^0-9]/g;

const sortAlphaNum = (a, b, order='asc') => {
    let inf = order==='asc' ? 1 : -1;
    var aA = a.replace(reA, "");
    var bA = b.replace(reA, "");
    if (aA === bA) {
        var aN = parseInt(a.replace(reN, ""), 10);
        var bN = parseInt(b.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? inf : inf*(-1);
    } else {
        return aA > bA ? inf : inf*(-1);
    }
}

export {renameProperty, getFirstElementOfObj, getNestedObjectRef, getNestedObjectRefSimple, removeArrayElement, round, calculateAge, asyncForEach, getRandomInt, sortAlphaNum};