import React, {useState, useRef} from 'react';

import { TextField, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';


function GroupHeader (props)
{
    const [name, setName] = useState(props.groupName)
    const [inputTimer, setInputTimer] = useState(0)
    const ref = useRef(null);

    const refocus = () =>{
        ref.current.focus()
    }
    const onChange = (e) => {
        let newName =e.currentTarget.value;
        let oldName =e.currentTarget.name;

        var duration = 800;
        setName(newName);
        if(inputTimer) clearTimeout(inputTimer)
        setInputTimer (setTimeout(()=>{
            props.onChangeName( oldName, newName ).then(refocus);
        }, duration));
    }


    return (
        <div style={{width: "100%", height: 50, background: "black"}}>
            <TextField ref={ref} margin="dense" label="Group" name={props.groupName} value={name} variant="outlined" onChange={onChange} />
            <IconButton name={props.groupName} style={{float: "right"}} color="inherit" onClick={props.onDeleteGroup}>
                <DeleteIcon />
            </IconButton>
        </div>
    )
}


export default GroupHeader
