import React, { useEffect } from 'react';
//import GridList from '@material-ui/core/GridList';

import TopBarMenu from './elements/TopBarMenu';
import ProgramSelection from './ProgramSelection';

import { Provider, Subscribe } from 'unstated-no-context-polyfill';
import MenuContainer from '../../container/Menu';
import withAuthorization from '../../router/WithAuthorization';
import { withFirebase } from '../../container/firebase';
import { setCurrentParticipantPositonWithRef } from '../../lib/ProgramCalculations';
import ProgramMenu from './ProgramMenu';
import SelectionWrapper from './SelectionWrapper';

function StartMenu(props) {

    const menuContainer = new MenuContainer({

    });

    const getInitialData = async () => {
        if (props.firebase.auth.currentUser != null) {
            /*const userData = await props.firebase.getInitialData();*/
            menuContainer.setState(props.firebase.userData);
            props.firebase.getMenuData().then(data => menuContainer.setState(data));
        }
    }
    useEffect(() => {
        getInitialData();
    }, [props.firebase.auth.currentUser]);

    return (
        <Provider inject={[menuContainer]}>
            <Subscribe to={[MenuContainer]}>
                {
                    menu => (
                        <div style={{ height: "100vh" }}>
                            <TopBarMenu logout={props.firebase.doLogOut} />
                            {
                                menu.state.selectedProgramIndex !== null ?
                                    <ProgramMenu program={menu.getSelectedProgram()} /> :
                                    <SelectionWrapper />
                            }
                        </div>)
                }
            </Subscribe>
        </Provider>
    );

}

export default withAuthorization(authUser => !!authUser)(withFirebase(StartMenu));