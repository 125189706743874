import React, {useState} from 'react';
import { Dialog, DialogActions, DialogContent,DialogTitle, Button, TextField, Typography,
  List,ListItem,ListItemSecondaryAction,ListItemText,IconButton, Divider } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { countToEighCount } from 'lib/ProgramCalculations';

const StyledListItem = withStyles((theme) => ({
  root: {
      background: theme.fieldColorActive,
      margin: 0.5,
    //  borderRadius: 5  
  },
}))((props) => (
  <ListItem
    {...props}
  />
));


export default function WaypointManagerDialog (props){

  const [dense, setDense] = React.useState(false);
  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const presentCount = (count) => {
    let eightCount = countToEighCount(count);
    return eightCount["8"] + " - " + eightCount["1"];
  }

  if(props.isOpen)
  {
    const deleteWaypoint = (e) =>{
      let count = e.currentTarget.id.split("-")[0];
      props.removeWaypointOfParticipants(count, props.selectedParticipantIds);
    }
    const waypoints = props.getCommonWaypointCounts(props.selectedParticipantIds);
    const seqnames = props.getSequencesNames(waypoints);

    return (
      <Dialog open={props.isOpen} onClose={props.handleClose} aria-labelledby="Waypoint-Manager"  fullWidth={true}>
        <DialogTitle id="Waypoint-Manager">Waypoint-Manager</DialogTitle>
        <DialogContent dividers>
          <Typography variant="subtitle2" gutterBottom>
            {props.selectedParticipantIds.map((id, i) => i<props.selectedParticipantIds.length-1 ? id +", ": id+":")} 
          </Typography>
          <List dense>
          {
            props.isOpen ? waypoints.map((w,index) =>
            <React.Fragment key={index}>
              <StyledListItem>
                <ListItemText
                      primary={presentCount(w.count) + (w.areAllWaiting ? " - PAUSE to next Waypoint" : " ")}
                      secondary={seqnames[index]}
                />
                <ListItemSecondaryAction>
                  <IconButton id={w.count} edge="end" aria-label="delete" onClick={deleteWaypoint} disabled={waypoints.length<2}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </StyledListItem>
              <Divider variant="inset" component="li" />
              </React.Fragment>
            ) : null
          }
          </List>
        </DialogContent>
        <DialogActions style={{marginTop: 10}}>
          <Button variant="contained" name="newParticipantDialogOpen" onClick={props.closeDialog} >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  else
    return null;
};