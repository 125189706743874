import React from 'react';
import { Subscribe } from 'unstated-no-context-polyfill';
import SequenceContainer from '../../../../container/program/Sequence'

class CounterTimeLine extends React.Component {

    getStyle = count => ({
        width: 50,
        height: this.props.grid -2,
        border: "1px solid rgba(0, 0, 0, 0.5)",
        background: "rgba(255, 255, 255, " + (this.getEightCount(count)%2 === 1 ? "0" : "0.2") + ")",
        
    })

    getEightCount = count => Math.floor(count/8+1)
    
    getRows= counts =>{
        let rows = [];
        for(var i = 0; i < counts; i++)
        {
            rows.push(<div key={i} style={this.getStyle(i)}>{Math.floor(i/8+1) + " - " + (i%8+1)}</div>)
        }
        return rows;
    }

    render = () =>
    {
        return(
            <Subscribe to={[SequenceContainer]}>
            {
                sequenceContainer =>
                (
                    <div style={{width:50, fontSize: 10, display: "inline-block", marginTop: sequenceContainer.state.visibleHasGroups ? 51 : 0}}>
                        <div style={{height: 8}}></div>
                        {this.getRows(sequenceContainer.state.visibleSequence.counts)}
                    </div>
                )
            }
            </Subscribe>
        )
    }
}

export default CounterTimeLine;