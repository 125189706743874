
import React, {} from 'react';
import { withTheme } from '@material-ui/core';
import SequenceSelectorList from './SequenceSelectorList';
import { Subscribe } from 'unstated-no-context-polyfill';

import ProgramContainer from 'container/program/Program';
import TeamContainer from 'container/program/Team';
import SequenceContainer from 'container/program/Sequence';

function LeftPanelControl (props){

    return (
        <Subscribe to={[SequenceContainer, TeamContainer, ProgramContainer]}>
        {
            (sequence, team, program) =>
            <div style={{ height: "100%", width: "20%", position: "absolute", left: 0, boxShadow: props.theme.boxShadow, background: props.theme.listBackgroundActive}}>
                <div style={{ margin: 20}}>
                   <SequenceSelectorList sequence={sequence} program={program}/>
                </div>
            </div>
        }
        </Subscribe>
    )
};

export default withTheme(LeftPanelControl)