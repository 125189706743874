import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import MusicCalculator from '../../../../lib/MusicCalculator';

import * as music_meta from 'music-metadata-browser';

function MusicUpload (props) {

    const onChange = (e) => {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        const reader = new FileReader();
        music_meta.parseBlob(f).then (metadata =>
        {
            reader.onload = async (e) => {
                const buffer = e.target.result.slice(0);
                let bpm = await MusicCalculator.getBpm(e.target.result,metadata.format.sampleRate, metadata.format.duration);
                props.setDataFn({
                    buffer,
                    bpm,
                    duration: metadata.format.duration,
                    name: f.name,
                    lastModifiedDate: f.lastModifiedDate,
                    file: f
                });
            }
            reader.readAsArrayBuffer(f);
        })
    }

    return (
        <React.Fragment>
            <input
                accept="audio/*"
                style={{display: "none"}}
                id="contained-button-file"
                type="file"
                onChange={onChange}
            />
            <label htmlFor="contained-button-file">
                <Button variant="contained" component="span">
                    Select Music
                </Button>
            </label>
        </React.Fragment>
    );
}

export default MusicUpload;