
import React from 'react';
import { Typography, ButtonBase } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import {countToEighCount} from 'lib/ProgramCalculations';

function ArrowButtonTemplate (props){
    return (
        <ButtonBase onClick={props.onClick} id={props.id}>
            <div style={{width: "50px", height: "15px", }}>
                <svg height="15" width="50"  >
                    <defs>
                        <linearGradient id="arrow" gradientTransform="rotate(90)">
                            <stop offset="5%"  stopColor={props.theme.panelEndColor} />
                            <stop offset="95%" stopColor={props.theme.panelStartColor} />
                        </linearGradient>
                    </defs>
                    {props.isUp ? <polygon points="0,15 50,15 25,4" fill={props.theme.panelStartColor}/> : <polygon points="0,0 50,0 25,11" fill={props.theme.panelEndColor} />}
                </svg>
            </div>
        </ButtonBase>
    )
}


const ArrowButton = withTheme(ArrowButtonTemplate);

function Counter (props){
    
    const decreaseCount = (e) =>{
        let count = props.program.onDecreaseCount(e);
        if (!count)
            return;

       props.program.setPath(props.sequence.getSequencePathByCount(count));
    }
    const increaseCount = (e) => {
        let count = props.program.onIncreaseCount(e);
        if (!count)
            return;

        props.program.setPath(props.sequence.getSequencePathByCount(count))
    }

    return (
        <div style={{width: props.width,display: "flex", alignItems: "space-around", justifyContent:"space-around", flexDirection: "column", height: "100%"}}>
           
            <ArrowButton id={props.id} isUp={true} onClick={increaseCount}/>

            <div id="counter" style={{height: "40px", width: "100%", backgroundImage: props.theme.panelColor, borderRadius: 2, /*boxShadow: props.theme.boxShadow,*/
                display: "flex",alignItems: "center", justifyContent:"center", flexDirection: "column"}}>
                <Typography variant="h4" component="h4" /*style={{textShadow: props.theme.shadowText}}*/>{countToEighCount(props.program.state.currentCount)[props.id]}</Typography>
                <span style={{height: "5px", position: "relative", bottom: 10,  fontSize: 9, opacity: 0.7}}>{props.label}</span>
            </div>
            <ArrowButton id={props.id} isUp={false} onClick={decreaseCount}/>
        </div>
    )
};

export default withTheme(Counter);
