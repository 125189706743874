import React, {useState} from 'react';
import { Dialog, DialogActions, DialogContent,DialogTitle, Button, TextField } from '@material-ui/core';

let shortNotSet = true;
const emptyParticipant = {name: "", id: "", positions: [], short: ""};
export default function NewParticipantDialog (props){
    const [participant, changeParticipant] = useState(emptyParticipant);

    const onInputChange= (e) => {
        let p = {...participant};
        
        if(e.currentTarget.name === "short")
          shortNotSet = false;
        else if (shortNotSet)
        {
            p.short = "";
            e.currentTarget.value.split(' ').map(s => s[0] !== "" && s[0]  ? p.short += s[0].toUpperCase() : null)
        }

        p[e.currentTarget.name] = e.currentTarget.value;
        changeParticipant(p)
    }

    const createParticipant = () => {
        if (participant.name === "" && participant.short === "")
            return;
        participant.id = participant.short;
        resetDialog();    
        props.createParticipant(participant);
    }

    const resetDialog = () => {        
        changeParticipant(emptyParticipant);
        shortNotSet = true;
    }
    const cancelDialog = () => {
        resetDialog();
        props.closeDialog();
    }

    return (
      <Dialog open={props.isOpen} onClose={props.handleClose} aria-labelledby="simple-dialog-title" maxWidth={"xs"} fullWidth={true}>
        <DialogTitle id="simple-dialog-title">Participant</DialogTitle>
        <DialogContent >
          <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
              <TextField autoComplete="off" fullWidth required label="Name" margin="dense" name={"name"} value={participant.name} onChange={onInputChange} variant="outlined"/>
              <TextField autoComplete="off" fullWidth required label="Acronym" margin="dense" name={"short"} value={participant.short} onChange={onInputChange} variant="outlined"/>
          </form>
        </DialogContent>
        <DialogActions>
          <Button name="newParticipantDialogOpen" onClick={cancelDialog}>
            Cancel
          </Button>
          <Button variant="contained" name="newParticipantDialogOpen" onClick={createParticipant} >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    )
};