import React from 'react';

//import { TextField } from '@material-ui/core';

import TeamContainer from '../../container/program/Team';
import SequenceContainer from '../../container/program/Sequence';
import { Subscribe } from 'unstated-no-context-polyfill';
import { Typography } from '@material-ui/core';

class ChantEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            success: null,
        } 
    }

    render() {
        return (
            <Subscribe to={[SequenceContainer, TeamContainer]}>
            {
                (sequence, team) =>
                (
                    <div style= {{height: "100%", width: "100vw", alignItems: "center", display: "flex", justifyContent: "center", position: "absolute"}}>
                        <Typography component="h1" variant="h3">Coming Soon!</Typography>
                    </div>
                )
            }
            </Subscribe>
        );

    }
}

export default ChantEditor;