import React from 'react';

import { withTheme } from '@material-ui/core/styles';
import { Subscribe } from 'unstated-no-context-polyfill';
import MenuContainer from './../../../container/Menu'; 
import { AppBar, Toolbar, Typography, IconButton, Grid,Avatar,makeStyles } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SaveIcon from '@material-ui/icons/Save';
import {blueGrey} from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import UserAvatarMenu from './UserAvatarMenu'; 

const useStyles = makeStyles((theme) => ({
    user: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        color: theme.palette.getContrastText(blueGrey[500]),
        backgroundColor: blueGrey[500],
        boxShadow: `0 0 2px 1px ${theme.palette.getContrastText(blueGrey[500])}`,
      },

}));

function TopBarSeq (props) {
    const classes = useStyles();
    /*============================================================================
    ===============================   Childs   ===================================
    ==============================================================================*/
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };


    return (
        <Subscribe to={[MenuContainer]}>
            {
                menu =>
                    <AppBar position="sticky" >
                        <Toolbar>
                            <Grid container justify="space-between" alignItems= 'center'>
                                <Grid item>
                                    <IconButton edge="start" disabled={menu.state.selectedProgramIndex === null}
                                        aria-label="menu" onClick={()=>menu.selectProgram(null)}>
                                        <NavigateBeforeIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item >
                                    <Typography variant="h5" style={{display: 'inline-block'}} >
                                        {menu.state.selectedProgramIndex !== null? menu.getSelectedProgram().name : menu.state.clubName }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton edge="start" color="inherit" onClick={handleClick}>
                                        <Avatar className={classes.user}>{menu.state.userTag}</Avatar>
                                    </IconButton>
                                    <UserAvatarMenu handleClose={handleClose} anchorEl={anchorEl} logout={props.logout} name={menu.state.userName}/>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
            }
        </Subscribe>
        )

}
export default withTheme(TopBarSeq);


/*
{sequence.state.path.length>-1? 
                                                <ButtonGroup orientation="vertical" style={{margin: 10}}>
                                                    <Button onClick={sequence.increaseCounts}>
                                                    <ArrowUpwardIcon fontSize="inherit" />
                                                    </Button>
                                                    <Button onClick={sequence.decreaseCounts}>
                                                        <ArrowDownwardIcon fontSize="inherit" />
                                                    </Button>
                                                </ButtonGroup>: null
                                            }*/