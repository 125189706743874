
import React, { PropsWithChildren, useRef } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import { useDrag } from 'react-dnd'
import { DndProvider, createDndContext } from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {TouchBackend} from "react-dnd-touch-backend";


const isTouchDevice = () => {
  if ("ontouchstart" in window) 
    return true;
  return false;
};

const RNDContext = createDndContext(isTouchDevice() ? TouchBackend : HTML5Backend);

function DragDropContext({ children }) {
  const manager = useRef(RNDContext);
  return <DndProvider manager={manager.current.dragDropManager}>{children}</DndProvider>;
}
export default DragDropContext;

