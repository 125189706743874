import React from 'react';
import Sequence from './Sequence';

import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { withTheme } from '@material-ui/core/styles';
import { Subscribe } from 'unstated-no-context-polyfill';
import SequenceContainer from '../../../../container/program/Sequence'

import GroupHeader from './GroupHeader';
import SequenceAddButtons from './SequenceAddButtons';



class SequenceField extends React.Component {

    constructor (props){
        super(props);
       
        this.space = 2;
    }

    getListStyle = (isDraggingOver, counts, amountGroup)=> {
        let width = this.props.width === "auto" ? "auto" : this.props.width * (1/amountGroup);
        return ({
            background: isDraggingOver ? this.props.theme.listBackgroundActive : this.props.theme.listBackground,
            padding: 8,
            width: width+"%",
            height: (this.props.grid * counts - this.space*2) + (amountGroup > 1? 50 : 0),
            marginTop: this.props.marginTop,
            display: "inline-block",
            verticalAlign: "top",
            position: "relative"
        });
    }

    render (){
        return (
            <Subscribe to={[SequenceContainer]}>
            {
                sequence =>
                    <DragDropContext onDragEnd={sequence.onDragEnd}>
                        {
                            sequence.state.visibleSequence ?  Object.keys(sequence.state.visibleSequence.sequences).map(g =>
                            (
                                <Droppable droppableId={g} type={g} key={g}>
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={this.getListStyle(snapshot.isDraggingOver, sequence.state.visibleSequence.counts, Object.keys(sequence.state.visibleSequence.sequences).length)}
                                    >
                                        { sequence.state.visibleHasGroups ?
                                                <GroupHeader groupName={g} onChangeName={sequence.renameGroup} onDeleteGroup={sequence.removeGroup}/> :null
                                        }
                                        {
                                            sequence.state.visibleSequence.sequences[g].map((s, index) => (
                                                <Sequence key={s.id} sequence={s} groupName={g} index={index} grid={this.props.grid} onInputChange={sequence.onChangeCount} zoom={sequence.zoom}/>
                                            ))
                                        }
                                        <SequenceAddButtons sequences={sequence.state.visibleSequence.sequences} showAddGroup={true}
                                            onClickSequence={sequence.addSequence} onClickGroup={sequence.addGroup}/>
                                        
                                    {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                            )):null
                    }
                    </DragDropContext>
            }
            </Subscribe>
        );
    }

}

export default withTheme(SequenceField);