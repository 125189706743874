import React from 'react';
import { Subscribe } from 'unstated-no-context-polyfill';
import ProgramContainer from './Program';
import TeamContainer from './Team';
import SequenceContainer from './Sequence';


export const withProgram = (Component) => (props) => (
<Subscribe to={[SequenceContainer, TeamContainer, ProgramContainer]}>
{
    (sequence, team, program) => (
        <Component {...props} program={program} team={team} sequence={sequence}/>
    )
}
</Subscribe>);