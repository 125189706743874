import React, { useState, Component } from 'react';
import { makeStyles, Container, Typography, Box, IconButton, Button, Chip } from '@material-ui/core/';
import { Link } from "react-router-dom";
import FilterList from '../customComponents/Filterlist';
import MUIDataTable from "mui-datatables";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Dial from '../customComponents/Dial';

import * as ROUTES from './../../router/routes';
import { withRouter } from 'react-router-dom';
import { withMenu } from '../../container/Menu';

import { Subscribe } from 'unstated-no-context-polyfill';


const useStyles = makeStyles((theme) => ({
    background: {
        height: "100vh",
    },
    container: {
        width: "100%",
        position: "relative",
        height: window.innerHeight - 64 - 72,
        overflowY: "scroll"
    },
    table: {
        marginTop: theme.spacing(10),
        width: "100%"
    },
    newButton: {
        position: "absolute",
        left: 0,
        margin: theme.spacing(4),
    },
}));

const selectColumn =
{
    name: "14",
    label: " ",
    options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <IconButton component={Link} to={ROUTES.EDITING} onClick={() => console.log(tableMeta.columnData.name)}>
                    <ChevronLeftIcon />
                </IconButton>
            );
        }
    }
};

const teamsColumn = (program, teams) => ({
    name: 'Teams',
    options: {
        filter: true,
        filterType: 'multiselect',
        customBodyRenderLite: (dataIndex) => {
            let value = program[dataIndex].teams;
            return value.map((val, key) => {
                let name = "Unknown";
                const team = teams.find(t => t.id === val);
                if (team !== undefined)
                    name = team.name;
                return <Chip
                    variant="outlined"
                    color="primary"
                    label={name} key={key}
                />;
            });
        },
    }
})


const mappingLabelValue = (data) => ({
    "Name": data["name"],
    "Counts": data["maxCount"],
    "BPM": data["bpm"],
    "Participants": data["amountParticipants"],
    "Last Edit": data["lastEdit"] ? data["lastEdit"].toLocaleString().slice(0, -3) : null,
})
const getColumnsAndDataByPrograms = programs => {
    let data = [];
    programs.forEach((p) => {
        let row = [];
        let m = mappingLabelValue(p);
        Object.values(m).forEach(k => {
            row.push(k)
        })
        data.push(row);
    })

    return data;
}



const CreateDialBtn = (props) => {
    return (
        <Button variant="contained" color="secondary"  {...props}>New Program</Button>
    );
};


function ProgramSelection(props) {
    const classes = useStyles();
    const columns = [selectColumn, ...Object.keys(mappingLabelValue({})), teamsColumn(props.menu.state.programs, props.menu.state.teams)];


    const options = {
        download: false,
        print: false,
        isRowSelectable: false,
        selectableRowsHideCheckboxes: true,
        selectableRowsHeader: false,
        rowHover: true,
        onRowClick: (e, meta) => { props.menu.selectProgram(meta.dataIndex) }
    };
    return (
        <Box className={classes.background}>
            <Container className={classes.container}>
                <MUIDataTable
                    className={classes.table}
                    title={"Programs"}
                    data={getColumnsAndDataByPrograms(props.menu.state.programs)}
                    columns={columns}
                    options={options}
                    components={{
                        TableFilterList: FilterList,
                    }}
                />
                <Button variant="contained" color="secondary" className={classes.newButton} onClick={() => props.history.push(ROUTES.EDITING, { programId: null })} >New Program</Button>
            </Container>
        </Box>
    );
}
export default withMenu(withRouter(ProgramSelection));