
import React, {} from 'react';
import {IconButton} from '@material-ui/core';
import IconTemplate from './IconTemplate';


function MediaButton (props) {
    return (
       <IconButton size={"small"} onClick={props.onClick} >
           <IconTemplate  path={props.iconPath}/>
       </IconButton>
    )
}


export default MediaButton;