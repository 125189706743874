
import React, {useState} from 'react';
import { Grid, Fab, Button, makeStyles, Chip, Select, MenuItem, Divider, Box } from '@material-ui/core';

import TeamContainer from 'container/program/Team';
import SequenceContainer from 'container/program/Sequence';
import { Subscribe } from 'unstated-no-context-polyfill';

import {withFirebase} from 'container/firebase';

import InactiveParticipantField from './InactiveParticipantField'
import { withTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ParticipantDialog from './ParticipantDialog';



const useStyles = makeStyles((theme) => ({
        panel: {
            background: theme.palette.background.dark,
            position: "relative",
            height: "100%",
            width: "100%"
        }, 
        chip: {
            margin: theme.spacing(1),
        },
        participants: { 
            width: "100%",
            height: "calc(100% - 162px)"
        },
        control: {
            width: "100%",
            background: theme.palette.action.disabled,
            boxShadow: "2px 0px 5px 2px rgba(0, 0, 0, .3)",
 
        },
        bottomControl: {
            position: "absolute",
            bottom: theme.spacing(1),
            width: "100%",
        },
        select:{
            width: "60%",
        },
        selectButton: {
            marginLeft: theme.spacing(2),
            margin: theme.spacing(1),
            height: "auto",
        },
        addButton:{
            borderRadius: 0,
            width: "100%",
            bottom: 0
        },
}))


function RightPanel (props){
    const classes = useStyles();
    const [participantDialogOpen, setParticipantDialogOpen] = useState(false);
    const [selectedTeamIndex, setSelectedTeamIndex] = useState(0);

    const closeDialog = (e) => {
        setParticipantDialogOpen(false);
    }
    const openDialog = (e) =>{
        setParticipantDialogOpen(true);
    }

    const selectTeam=(e, v) =>{
        setSelectedTeamIndex(v.props.value)
    }

    const addSelectedTeam = (fn) => () => {
        setSelectedTeamIndex(0);
        fn(selectedTeamIndex, getAthlets)
    }

    const getAthlets = async (id) => {
        return await props.firebase.getAthletsOfTeam(id, true);
    }
    
    return (
    <Subscribe to={[SequenceContainer, TeamContainer]}>
    {
        (sequence, team) => (
            <Box className={classes.panel}>
                <Box className={classes.control}>
                    {
                        team.state.activeTeamTemplates.map ((t, i) => 
                            <Chip className={classes.chip} key={i} variant="outlined" color="primary" 
                            label={t.name} onDelete={team.removeActiveTeamTemplate(i)}></Chip>
                        )
                    }
                </Box>
                <Box className={classes.participants}>
                    <InactiveParticipantField inactiveParticipants={team.state.participants.inactive} isPartOfTeam={team.isPartOfTeam} removeParticipant={team.removeParticipant}/>
                </Box>
                <Box className={`${classes.control} ${classes.bottomControl}`}>
                    <Grid item xs={12}>
                        <Select className={classes.select} value={selectedTeamIndex} onChange={selectTeam} disabled={team.state.availableTeamTemplates.length <= 0}>
                            {team.state.availableTeamTemplates.map((t,i) => <MenuItem key={i} value={i}>{t.name}</MenuItem>)}
                        </Select>
                        <Button className={classes.selectButton} variant="contained" color="secondary" size="small" 
                            onClick={addSelectedTeam(team.setTeamTemplateActive)} disabled={team.state.availableTeamTemplates.length <= 0}>Add</Button>
                        <ParticipantDialog isOpen={participantDialogOpen} closeDialog={closeDialog} createParticipant={team.createParticipant} />
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button className={classes.addButton} variant="contained" color="secondary" onClick={openDialog}>Add independent Participant</Button>
                    </Grid>
                </Box>
            </Box>
        )
    }
    </Subscribe>)
    }
    export default withTheme(withFirebase(RightPanel));
            
              /*
              <Fab name="newParticipantDialogOpen" style={{position: "absolute", right:"15px", bottom: "15px"}} onClick={openDialog}>
                                <AddIcon />
                            </Fab>*/