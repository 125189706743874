import { Container } from 'unstated-no-context-polyfill';
import { removeArrayElement } from '../../lib/Utilities';
import Participant from '../../components/editing/elements/team/Participant';

class TeamContainer extends Container  {

    constructor(props = {}) {
        super();
        this.state = {
            participants: JSON.parse(JSON.stringify(props.team.participants)),
            activeTeamTemplates: JSON.parse(JSON.stringify(props.team.activeTeamTemplates)),
            availableTeamTemplates: JSON.parse(JSON.stringify(props.team.availableTeamTemplates))
        };
    }

    /*=======================================================================*/
    /*========================         MAT         ==========================*/
    /*=======================================================================*/

    
    getTeamData = ()  =>({
        active: this.state.participants.active,
        inactive: this.state.participants.inactive,
        notSetted: this.state.participants.notSetted,
        activeTeamTemplates: this.state.activeTeamTemplates.map(t => t.id),
    })

    addParticipantToUnsetted = (id, setState=true) => {
        if(!this.state.participants.notSetted.includes(id))
        {
            let participants = JSON.parse(JSON.stringify(this.state.participants));
            participants.notSetted.push(id);
            if(setState)
                this.setState({participants});
            else
                return participants.notSetted;
        }
    }


    removeNotSettedParticipant = (id, setState=true) =>{
        if(this.state.participants.notSetted.includes(id))
        {
            let participants = JSON.parse(JSON.stringify(this.state.participants));
            participants.notSetted = removeArrayElement(this.state.participants.notSetted, id)
            if(setState)
                this.setState({participants});
            else
                return participants.notSetted;
        }
        else
            return null;
    }
    /*=======================================================================*/
    /*========================  TEAM MANAGEMENT  ============================*/
    /*=======================================================================*/

    isPartOfTeam = (id) => {
        for(let i = 0; i < this.state.activeTeamTemplates.length; i++)
        {
            if(this.state.activeTeamTemplates[i].athletes[id])
                return true;
        }
        return false;
      }

    getTeamNameById = () =>{
        
    }
    setTeamTemplateActive = async (index, getAthlets) => {
        let availableTeamTemplates = JSON.parse(JSON.stringify(this.state.availableTeamTemplates));
        let activeTeamTemplates = JSON.parse(JSON.stringify(this.state.activeTeamTemplates));
        let participants = JSON.parse(JSON.stringify(this.state.participants));
        let team = availableTeamTemplates.splice(index, 1)[0];
        team.athletes = await getAthlets(team.id);

        Object.keys(team.athletes).forEach(id => {
            team.athletes[id].short = team.athletes[id].short ? team.athletes[id].short : this.createShort(team.athletes[id].name);
            team.athletes[id].id = id;
            if (!participants.active[id] && !participants.inactive[id])
                participants.inactive[id] = team.athletes[id];
        })

        activeTeamTemplates.push(team);  
        this.setState({activeTeamTemplates, availableTeamTemplates, participants});
    }

    removeActiveTeamTemplate = (index) => () => {
        let availableTeamTemplates = JSON.parse(JSON.stringify(this.state.availableTeamTemplates));
        let activeTeamTemplates = JSON.parse(JSON.stringify(this.state.activeTeamTemplates));
        let team = activeTeamTemplates.splice(index, 1)[0];

        availableTeamTemplates.push(team);  
        this.setState({activeTeamTemplates, availableTeamTemplates});
    }

    createShort = (name) => {
        let short ="";
        name.split(' ').map(s => s[0] !== "" && s[0]  ? short += s[0].toUpperCase() : null);
        return short;
    }

    addTeam= (e) => {


    }
    createParticipant = (participant) =>{
        let participants = JSON.parse(JSON.stringify(this.state.participants));
        participants.inactive[participant.id] = participant;
        this.setState({participants});
    }
    removeParticipant = (e) => {
        let participants = JSON.parse(JSON.stringify(this.state.participants));
        delete participants.inactive[e.currentTarget.name];
        this.setState({participants})
    }

    changeParticipantStatusList = (source, destination, droppableSource, droppableDestination) => {
 
        let key = Object.keys(source)[droppableSource.index];
        destination[key] = source[key];
        delete source[key];

        const result = {};
        result[droppableSource.droppableId] = source;
        result[droppableDestination.droppableId] = destination;
        return result;
    }

    onDragEndTeamManagement = removeFromMat => async result => {
        const { source, destination } = result;
        if (!destination || source.droppableId === destination.droppableId) {
            return;
        }
        else {
            let participants = JSON.parse(JSON.stringify(this.state.participants))
            const id = Object.keys(participants[source.droppableId])[source.index]
            const result = this.changeParticipantStatusList(
                participants[source.droppableId],
                participants[destination.droppableId],
                source,
                destination
            );
            
            participants.active = result.active; 
            participants.inactive =  result.inactive;
            
            if(destination.droppableId === "inactive")
            {
                if(this.state.participants.notSetted.includes(id))
                {
                    participants.notSetted = this.removeNotSettedParticipant(id, false);
                    this.setState({ participants});
                }
                else
                {
                    await removeFromMat(id)
                    this.setState({participants})
                }
                    
            }
            else if(destination.droppableId === "active")
            {
                participants.notSetted = this.addParticipantToUnsetted(id, false);
                this.setState({ participants });
            }
        }
    }

    changeParticipant = (id, statusGroup, newParticipantData) =>{
        let participants = JSON.parse(JSON.stringify(this.state.participants[statusGroup]))
        participants[id] = newParticipantData;
        let state = {};
        state[statusGroup] = participants;
        this.setState(state);
    }
}

export default TeamContainer;
