import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as firebase from 'firebase';

import { withRouter } from 'react-router-dom';
import AuthUserContext from './../../router/context';
import * as ROUTES from './../../router/routes';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="">
        JM
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
    background:{
        backgroundImage: "./blue-nebula.gif",
        height: "100vh",
        display: "flex",
        alignItems: "center"
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.2)',
        padding: 40
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Login(props) {

    const [loginData, setLoginData] = useState({username: "", password: ""});
    const [errorMessage, setErrorMessage] = useState("");
    const classes = useStyles();
    const validate = () =>{
        checkLogin();
    }

    const  onChangeLoginData = (e) =>{
        let newLoginData = {...loginData};
        newLoginData[e.currentTarget.name] = e.currentTarget.value;
        setLoginData(newLoginData);
    }

    const checkLogin = () => {
        firebase.auth().signInWithEmailAndPassword(loginData.username + "@cheermanager.com", loginData.password).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;

            console.log(errorCode);
            setErrorMessage(error.message)
          });
    }
    
    return (
        <AuthUserContext.Consumer>
        {authUser => 
            <div className={classes.background}>
                { authUser === "init" || authUser===null ?  null : props.history.push(ROUTES.MAIN)}  
                <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                    Cheer Manager
                    </Typography>
                    <form className={classes.form} noValidate>
                    <Typography>{errorMessage}</Typography>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        value={loginData.username}
                        onChange={onChangeLoginData}
                        autoComplete="username"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={loginData.password}
                        onChange={onChangeLoginData}
                        autoComplete="current-password"
                    />
                    <Grid container style={{textAlign: "left"}}>
                        <Grid item xs>
                            <FormControlLabel 
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                        </Grid>
                    </Grid>
                        <Button
                            
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={validate}
                        >
                            Sign In
                        </Button>
                    </form>
                </div>
                <Grid container>
                        <Grid item xs>
                        <Link href="#" variant="body2">
                            Forgot password?
                        </Link>
                        </Grid>
                    </Grid>
                
                </Container>
            </div>
        }
        </AuthUserContext.Consumer>
    );
}

export default withRouter(Login);

/* <Box mt={8}>
        <Copyright />
      </Box>*/

    /*<Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
            
      <video className="BGVideo" data-autopause="false" data-loop="true" data-fill-mode="fill" loop playsInline autoPlay>
            <source src="video/Macro_Plex_4K_Motion_Background_Loop.mp4" type="video/mp4"/>
            
          </video>
      
            
            
            
            
            */