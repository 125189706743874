import React, { useState } from "react"
import { createContainer } from "unstated-next"



function useTeam(props) {
   // let [currentAthlet, setCurrentAthlet] = useState(emptyAthletTemplate)
    const [athlets, setAthlets] = useState(props.athlets)
    const [generalData, setGeneralData] = useState(props.generalData)
    const [isEdited, setIsEdited] = useState(false);

    const saveAthlet = (index) => (athletData) => {
      let athletsCopy = [...athlets];
      if(index !== null)
        athletsCopy[index] = athletData;
      else
        athletsCopy.push(athletData);
      
      setAthlets(athletsCopy);
      return calcStats(athletsCopy);
    }

    const calcStats = (newAthlets) => {
      let stats = {amount: newAthlets.length};
      newAthlets.forEach(a => {
        a.positions.forEach(pos => stats[pos] ? stats[pos]++ : stats[pos]=1);
      })
      return {stats, id: generalData.id};
    }

    const updateGeneralData = (data) =>{
      setGeneralData({...generalData, ...data});
    }

    const deleteAthlets = (indices) => {
      let athletsCopy = [...athlets];
      let idsToDelete = [];
      for(var i = indices.length-1; i >= 0; i--)
      {
        idsToDelete.push(athletsCopy[indices[i]].id)
        athletsCopy.splice(indices[i], 1);
      }
      setAthlets(athletsCopy);
      let stats = calcStats(athletsCopy);
      return [idsToDelete, stats];
    } 

    return { 
      athlets, saveAthlet,deleteAthlets, 
      generalData, updateGeneralData,
    }
  }
  
  let Container = createContainer(useTeam);


  export default Container;