import React, {useState, useEffect} from 'react';
import { Dialog, DialogActions, DialogContent,DialogTitle, Button, TextField, makeStyles, FormControlLabel, Checkbox, Box, Typography, Divider } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider  } from '@material-ui/pickers';
import DatePicker from './DatePicker';
import Positions from 'data/Positions';
import {removeArrayElement} from 'lib/Utilities';

import {withFirebase} from 'container/firebase'

const emptyAthletTemplate = {
  name: "",
  birthdate: null,//new Date('2014-08-18T21:11:54'),
  email: "",
  positions: [],
  short: "",

}


const useStyle = makeStyles(theme => ({
  dialog: {
    width: "100%",
    padding: theme.spacing(3),
  },
  box: {
    marginTop: theme.spacing(2),
    width: "100%",
    justifyContent: "space-between",
    alignContent: "space-between",
    display: "flex"
  },
  divider: {
    width: "100%",
    backgroundColor: "white",
    margin: theme.spacing(2)
  }

}))

function AthletDialog (props){
  const [data, changeData] = useState(props.athlet ? props.athlet : emptyAthletTemplate);
  useEffect(() =>{
    if(props.athlet)
      changeData(props.athlet);
  }, [props.athlet])

  const classes = useStyle();


  const onBirthdateChange = (value) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.birthdate = value;
    changeData(dataCopy);
  }
  const onPositionsChange = (e, value) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    let pos = e.currentTarget.name.split("-")[1];

    if (value)
      dataCopy.positions.push(pos)
    else
      dataCopy.positions = removeArrayElement(dataCopy.positions, pos);
    
    changeData(dataCopy);
  }

  const onInputChange = (e) => {
    const dataCopy = JSON.parse(JSON.stringify(data))
    dataCopy[e.currentTarget.name] = e.currentTarget.value;
    changeData(dataCopy);
  }

  const saveAthlet = async (e) => {
    const dataCopy = JSON.parse(JSON.stringify(data))
    if(typeof dataCopy.birthdate === "string")
      dataCopy.birthdate = new Date(dataCopy.birthdate)
    let id = await props.firebase.saveAthlet(props.teamId)(dataCopy);
    dataCopy.id = id;
    let stats = props.saveAthlet(dataCopy);
    props.firebase.saveTeamData(stats);
    resetDialog();
    closeDialog();
  }
  const closeDialog = () => props.handleClose()

  const resetDialog = () => {        
      changeData(emptyAthletTemplate);
  }
  const cancelDialog = () => {
      resetDialog();
      closeDialog();
  }

  const getPositionCheckboxes = () => (
    Object.keys(Positions).map((pos, index) => 
      <FormControlLabel
        control={<Checkbox style={{color: Positions[pos]}} checked={data.positions.includes(pos)} onChange={onPositionsChange} name={"position-"+pos} />}
        label={pos}
        key={index}
      />
  ))
  
  return (
    <Dialog className={classes.dialog} open={props.isOpen} onClose={props.handleClose} aria-labelledby="simple-dialog-title" maxWidth={"sm"} fullWidth={true}>
      <DialogTitle>Athlet</DialogTitle>
      <DialogContent >
        <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
            <TextField autoComplete="off" fullWidth required label="Name" margin="dense" name={"name"} value={data.name} onChange={onInputChange} variant="filled"/>
            <TextField autoComplete="off" fullWidth label="E-Mail" margin="dense" name={"email"} value={data.email} onChange={onInputChange} variant="filled"/>
            <Box className={classes.box}>
              <DatePicker label="Birthdate" value={data.birthdate} onChange={onBirthdateChange} name="birthdate" inputVariant={"filled"} margin="dense"/>
              <TextField autoComplete="off" label="Standard-Tag" margin="dense" name={"short"} value={data.short} onChange={onInputChange} variant="filled"/>
            </Box>
           
            <Divider className={classes.divider}/>
            <Box className={classes.box}>
              <Typography>Positions:</Typography>
              { getPositionCheckboxes() }
            </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button name="newParticipantDialogOpen" onClick={cancelDialog}>
          Cancel
        </Button>
        <Button variant="contained" name="newParticipantDialogOpen" onClick={saveAthlet} >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
};


export default withFirebase(AthletDialog);