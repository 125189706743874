import React, {useRef, useEffect} from 'react';
import { withTheme } from '@material-ui/core';
//import './index.css';

function Waveform (props) {
    const waveformRef = useRef();


    useEffect(() => {
        if(waveformRef.current){
            props.init(waveformRef, props.theme);
        }

        return props.clearWaveform;
    }, []);
    //wavesurfer.load('./../../../LM19 - Falcons - 160.mp3');

    return (<div id="waveFormParent" style={{width: "100%", height: "100%"}}><div ref={waveformRef}></div></div>);

}


export default withTheme(Waveform);