import {
    createMuiTheme
} from '@material-ui/core/styles';


const orangeTrans = 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)';
const orangeBoarder = '0 3px 5px 2px rgba(255, 105, 135, .3)';
const blackShadowText = '0 0 10px rgba(0, 0, 0, .8)'
//const blueGreyBG = "linear-gradient(170deg, #52657a, #344355)";
const blueGreyBGBar = "linear-gradient(180deg, #3c4d61, #344355 )";
//const blackInnerBoarder = 'cubic-bezier(0.4, 0, 0.2, 1)';
const backgroundDark = "#263341";
const backgroundLight = "#4f6176";
const textColor = "rgba(255, 255, 255, 1)";
const secondaryTextColor = "rgba(255,255,255,0.65)";
const invertedTextColor = "rgba(0,0,0,0.83)";
const boxShadow = "0 3px 5px 2px rgba(0, 0, 0, .3)";
const darkRed2 = createMuiTheme({

    palette: {
        type: "dark",
        background: {
            paper: "rgba(0, 0, 0, 0.87)",
            default: backgroundLight,
            dark: backgroundDark,
        },
        action: {
            disabled: "rgba(0, 0, 0, 0.2)",
        },
        primary: {
            main: "rgba(255, 255, 255, 0.87)"
        },
        text: {
            primary: "rgba(255, 255, 255, 0.87)",
            secondary: "rgba(255, 255, 255, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(255, 255, 255, 0.38)"
        }
    },
    overrides: {
        MuiButtonGroup: {
            root: {

                border: "1px solid rgba(0, 0, 0, 0.5)",
            },
            grouped: {

            },
        },
        // Style sheet name ⚛️
        MuiButton: {
            // Name of the rule
            text: {
                // Some CSS
                color: textColor,
                padding: '0 30px',
                height: 48,
            },
            contained: {
                borderRadius: 3,
                border: 0,
                color: textColor,
                height: 48,
                padding: '0 30px',
                background: orangeTrans,
                boxShadow: orangeBoarder,
            },
            containedPrimary: {
                color: textColor
            }
        },
        MuiFab: {
            root: {
                backgroundColor: "white",
                color: invertedTextColor
            },
            primary: {
                backgroundColor: "white",
                color: invertedTextColor,
                '&:hover': {
                    backgroundColor: "white",
                    color: invertedTextColor,
                }
            }
        },
        MUIDataTableBodyRow: {
            root: {
                cursor: "pointer",

            },
        },
        MuiSpeedDialAction: {
            staticTooltipLabel: {
                backgroundColor: "white",
                color: invertedTextColor,
            },
            fab: {
                backgroundColor: "white",
                color: invertedTextColor,
            }
        },
        MuiIconButton: {
            root: {
                color: textColor,
            },
            sizeSmall: {
                padding: 0,
            },

        },
        MuiOutlinedInput: {
            root: {
                color: textColor,
                //fontSize: 14,
                borderColor: 'white',
                colorSecondary: 'red',
                focused: {
                    color: textColor
                },
                '&$error': {
                    color: "red",
                    colorSecondary: 'red',
                }


            },
            notchedOutline: {
                borderColor: 'white !important',
            },
        },
        MuiAppBar: {
            root: {
                background: blueGreyBGBar,
            }

        },
        MuiTabs: {
            root: {
                background: blueGreyBGBar,
            },
            indicator: {
                background: "#90caf9"
            }
        },
        MuiTab: {
            root: {
                color: "rgba(255, 255, 255, 0.7)",
                '&$selected': {
                    color: "#90caf9"
                },
            },

        },
        MuiInputLabel: {
            root: {
                color: "lightgrey",
                "&$focused": {
                    color: textColor
                }
            },

        },
        MuiCard: {
            root: {
                background: orangeTrans,
                borderRadius: 3,
                border: 0,
                color: textColor,
                //  padding: '0 30px',
                boxShadow: orangeBoarder,
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: backgroundDark,
            }
        },
        MuiAutocomplete: {
            listbox: {
                color: textColor,
                backgroundColor: "grey",
            }
        },
        MuiDialogTitle: {
            root: {
                color: textColor
            }
        },

        MuiTreeItem: {
            group: {
                marginLeft: 7,
                paddingLeft: 18,
                borderLeft: `1px dashed `,
            }
        },
        MuiSlider: {
            root: {
                color: "#90caf9"
            }
        },
        MuiTypography: {
            root: {
                color: textColor,
            },
            colorTextSecondary: {
                color: secondaryTextColor,
            }
        }
    },
    app: {
        backgroundImage: "linear-gradient(170deg, #52657a, #344355)",
    },
    textColor: textColor,
    secondaryTextColor: secondaryTextColor,
    invertedTextColor: invertedTextColor,
    fieldColor: backgroundDark,
    fieldColorActive: backgroundLight,
    progressPassive: "#607790",
    progressActive: "#90caf9",
    listBackground: "#2f3943",
    listBackgroundActive: "#303c49",
    boxShadow: boxShadow,
    panelColor: 'linear-gradient(349deg, rgba(87,128,179,1) 0%, rgba(144,202,249,1) 65%)',
    panelStartColor: 'rgba(144,202,249,1)',
    panelEndColor: 'rgba(87,128,179,1)',
    shadowText: blackShadowText

});

/*
const lightBlue = {
    app: {
        backgroundImage: "linear-gradient(170deg, #c3d9e5,#eff3f7)"
    },
    textColor: "black",
    fieldColor: "#ffffff",
    fieldColorActive:"#eff4f7"
}
*/
var currentTheme = darkRed2;

export default currentTheme;