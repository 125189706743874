


import React from 'react';
import { Grid, Fab} from '@material-ui/core';

import TeamContainer from '../../../../container/program/Team';
import SequenceContainer from '../../../../container/program/Sequence';
import { Subscribe } from 'unstated-no-context-polyfill';
import Participant from './Participant';
import { Droppable } from "react-beautiful-dnd";

import { withTheme } from '@material-ui/core/styles';

function InactiveParticipantField (props) {



    const getListStyle = (isDraggingOver) => ({
        width: "100%",
        height: "100%",
        background: isDraggingOver ?  props.theme.listBackgroundActive:  "rgba(0,0,0,0)",
        display: "flex",
        padding: "20px",
        margin: 0,
        alignContent: "flex-start",
        position: "relative",
        overflowY: "auto",
    })

    const isPartOfTeamTemplates = (p, t) => !t.activeTeamTemplates.includes(p.teamTemplate);

    return(
        <Droppable droppableId="inactive" direction="horizontal">
            {(provided, snapshot) => (
                <Grid container spacing={3}
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                >
                    { props.inactiveParticipants ? 
                        Object.keys(props.inactiveParticipants).map((key,i) => 
                        {
                            let p = props.inactiveParticipants[key];
                            return <Participant key={i} index={i} participant={p} isRemovable={!props.isPartOfTeam(p.id)} //isPartOfTeamTemplates(p, props.isPartOfTeam(p.id))
                                        removeParticipant={props.removeParticipant}/>
                        })
                        : null
                    }
                <span style={{display: "none"}}>
                    {provided.placeholder}
                    </span>
                </Grid>
            )}
        </Droppable>
        )
  }
  export default withTheme(InactiveParticipantField);