const loopThroughAllWaypoints = (waypoints, maxCount) => fn => {
    let results = [];
    for (let count = 0; count < maxCount; count++)
    {
        if(waypoints[count])
        {
            let result = fn(waypoints[count], count);
            if(result)
                results.push(result);
        }
    }
    return results;
}

const loopThroughNearestWaypoints = (waypoints, startCount, maxCount, fn, includeCurrent=true, resetFn = () => {}) => {
    let result ={result: {next:null, prev: null} }
    for(let count = startCount+1; count < maxCount; count++)
    {
        if(waypoints[count])
        {
            result = fn("next", count, result.result);
            if(result.break)
                break;
        }
    }
    resetFn();
    for(let count = startCount - (includeCurrent?0:1); count > 0; count--)
    {
        if(waypoints[count])
        {
            result = fn("prev", count, result.result);
            if(result.break)
                break;
        }
    }
    return result.result;
}

const getNearestWaypointOfIdsIncludeCurrent = (ids, waypoints) => (count,maxCount) =>{
    let idsCopy = [...ids];
    const checkAndSaveWpIfIdExists = (direction, count, result) => 
    {
        const ids = [...idsCopy]
        ids.forEach(id =>{
            if(waypoints[count][id])
            {
                const index = idsCopy.indexOf(id);
                idsCopy.splice(index, 1);
                if(!result[direction])
                    result[direction] = {};
                result[direction][id] = {count, ...waypoints[count][id]};
            }
        }) 
        return {break: idsCopy.length === 0, result}
    }

    const resetIds = () =>{
        idsCopy = [...ids];
    }
    return loopThroughNearestWaypoints(waypoints, count, maxCount, checkAndSaveWpIfIdExists, true, resetIds);
}

const getNearestWaypointOfId = (id, waypoints) => (count,maxCount) =>{

    const checkAndSaveWpIfIdExists = (direction, count, result) => 
    {
        if(waypoints[count][id])
        {
            result[direction] = {count, ...waypoints[count][id]}
            return {break: true, result}
        }
        return {break: false, result};
    }
    return loopThroughNearestWaypoints(waypoints, count, maxCount, checkAndSaveWpIfIdExists, false);
}



const getTimeByCountDuration = (count, bpm, offset=0) =>{
    return count * (60/bpm) + offset;
}

const getSimpleCountByTime = (time, bpm) => Math.floor(time * bpm/(60));

const getCountByTime = (time, bpm, offset=0) =>{
    const simpleCount = getSimpleCountByTime(time-offset, bpm);
    return [
        simpleCount,
        time - getTimeByCountDuration(simpleCount, bpm,offset)
    ]
}


const calcCurrentPosition = (prevState, nextState, count, progress) =>{
    let result={};
    if(!nextState && prevState)
    {
        result.position = {x: prevState.x, y: prevState.y};
        result.prevCount = {...prevState.count};
        result.nextCount = null;
    }
    else if(nextState && !prevState)
    {
        result.position = {x: nextState.x, y: nextState.y};
        result.prevCount = null;
        result.nextCount = {...nextState.count};
    }
    else
    {
        let distance = {
            x: nextState.x - prevState.x, 
            y: nextState.y - prevState.y, 
        }

        let diffPrev2Next =  prevState.count-nextState.count; 
        let diffPrev2Current =  prevState.count-count;
        let progressWay = diffPrev2Current/diffPrev2Next;

        let position = {
            x: prevState.x + distance.x * progressWay, 
            y: prevState.y + distance.y * progressWay
        };
        result.prevCount = {...prevState.count};
        result.nextCount = {...nextState.count};
        result.duration = 0;
        result.position=position;
    }
    return result;
}

const countToEighCount = (simpleCount) => {
    let one;
    if (simpleCount < 0)
        one = (simpleCount+1)%8  -1;
    else
        one = (simpleCount-1)%8  +1;
    return {"8": simpleCount === 0 ? 0 : Math.floor((simpleCount-1)/8)+1 , "1": one};
}

const getMaxCountByProgram = programData => {
    let time = programData.duration - programData.musicOffset - (programData.containsChant ? programData.chantTime.chantEnd-programData.chantTime.chantEnd : 0 )
    return getCountByTime(time, programData.bpm)[0];
}

export {
    getMaxCountByProgram,
    loopThroughAllWaypoints,
    countToEighCount,
    getNearestWaypointOfId,
    getNearestWaypointOfIdsIncludeCurrent,
    getTimeByCountDuration, 
    getSimpleCountByTime,
    getCountByTime,
    calcCurrentPosition,
};