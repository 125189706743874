import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';



const StyledMenu = withStyles({
    paper: {
        background: "transparent",  
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
        margin: 5,
        background: "white",
        padding: "4px 16px",
        borderRadius: 4,
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1.5,
        height: 28,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: theme.invertedTextColor,

        },
        '&:hover':{
            background: "#d1d1d1",
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
              color: theme.invertedTextColor
            }

        }
       
    },
  }))(MenuItem);


export default function ParticipantMenu(props) {
    const handleClose = () =>{
        props.setAnchorNull();
    }
    
    const handleClick = (fn) => () =>{
      if(fn)
        fn();
      props.setAnchorNull();
    }

    return (
        <StyledMenu
          id="customized-menu"
          anchorEl={props.anchorEl}
          keepMounted
          open={Boolean(props.anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem onClick={handleClick(props.openWaypointDialog)}>
            <ListItemText primary="Handle Waypoints" />
          </StyledMenuItem>
          <StyledMenuItem onClick={handleClick(props.setWaypointsForSelectedPart)}>
            <ListItemText primary="Wait until now" />
          </StyledMenuItem>
        </StyledMenu>
    );
  }
  /*            
                        <ListItemIcon>
              <ControlPointIcon fontSize="small" />
            </ListItemIcon>
            
            */