
import React, { useState, Component } from 'react';
import { makeStyles, Container, Typography, Box, IconButton, Button } from '@material-ui/core/';
import { Link } from "react-router-dom";
import FilterList from '../customComponents/Filterlist';
import MUIDataTable from "mui-datatables";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NewProgramDial from './elements/NewProgramDial';
import Dial from '../customComponents/Dial';

import * as ROUTES from './../../router/routes';
import { withRouter } from 'react-router-dom';
import { withMenu } from '../../container/Menu';

import { Subscribe } from 'unstated-no-context-polyfill';


const useStyles = makeStyles((theme) => ({
    background: {
        height: "100vh",
    },
    container: {
        width: "100%",
        position: "relative",
        height: window.innerHeight - 64 - 72,
        overflowY: "scroll"
    },
    table: {
        marginTop: theme.spacing(10),
        width: "100%"
    },
    newButton: {
        position: "absolute",
        left: 0,
        margin: theme.spacing(4),
    },
}));

const selectColumn =
{
    name: "14",
    label: " ",
    options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <IconButton>
                    <ChevronLeftIcon />
                </IconButton>
            );
        }
    }
};

const mappingLabelValue = (data) => ({
    "Name": data["name"],
    "Level": data["level"],
    "Athletes": data.stats["amount"],
    "Flyer": data.stats["Flyer"],
    "Bases": data.stats["Base"],
    "Tumbler": data.stats["Tumbler"],
})
const getColumnsAndDataByPrograms = programs => {
    let data = [];
    programs.forEach((p) => {
        let row = [];
        let m = mappingLabelValue(p);
        Object.values(m).forEach(k => {
            row.push(k)
        })
        data.push(row);
    })
    return data;
}

function TeamSelection(props) {
    const classes = useStyles();

    const columns = [selectColumn, ...Object.keys(mappingLabelValue({ stats: {} }))];
    const options = {
        download: false,
        print: false,
        isRowSelectable: false,
        selectableRowsHideCheckboxes: true,
        selectableRowsHeader: false,
        rowHover: true,
        onRowClick: (e, meta) => { props.history.push(ROUTES.TEAMS, { teamId: props.menu.getTeamId(meta.dataIndex) }) }
    };

    return (
        <Box className={classes.background}>
            <Container className={classes.container}>
                <MUIDataTable
                    className={classes.table}
                    title={"Teams"}
                    data={getColumnsAndDataByPrograms(props.menu.state.teams)}
                    columns={columns}
                    options={options}
                    components={{
                        TableFilterList: FilterList,
                    }}
                />
                <Button variant="contained" color="secondary" className={classes.newButton}
                    onClick={() => props.history.push(ROUTES.TEAMS, { teamId: null })} >New Team</Button>
            </Container>
        </Box>
    );
}
export default withMenu(withRouter(TeamSelection));

