import React, {useState} from 'react';

import {Container, CssBaseline, withTheme, Grid} from '@material-ui/core/';

import { Link } from 'react-router-dom';

import { Subscribe } from 'unstated-no-context-polyfill';
import ProgramContainer from '../../container/program/Program';
import TeamContainer from '../../container/program/Team';
import SequenceContainer from '../../container/program/Sequence';


import MusicSide from './elements/music/MusicSide';
import ProgramSide from './elements/music/ProgramInfo';
import { getMaxCountByProgram } from '../../lib/ProgramCalculations';
import { validate } from '@material-ui/pickers';

class MusicHandler extends React.Component {

     constructor(props) {
        super(props)

        this.state={
            bpm: "",
            musicOffset: "",
            chantTime: {chantStart: 0, chantEnd: 0},
            countStart: 1,
            duration: "",
            counts: "",
            containsChant: false,
            error: "",
            isLoaded: false,
        }
        this.willUnmount = false
    }

    componentWillUnmount () {
        this.willUnmount=true;
    }

    changeContainsChant = (setValue) => (e) => {
        setValue(e.currentTarget.checked);
    };
    onChangeData = (checkValidation, setMusicState, setTopSeqCount) => (e) =>
    {   
        let name = e.currentTarget.name;
        let newState = {}
        let prevState=JSON.parse(JSON.stringify(this.state));
        if(name === "chantStart" || name==="chantEnd")
        {
            newState.chantTime = {...this.state.chantTime};
            newState.chantTime[name] =  e.currentTarget.value;
            prevState.chantTime[name] = e.currentTarget.value;
        }
        else if (name === "containsChant")
        {
            newState.containsChant = e.currentTarget.checked;
        }
        else
        {
            prevState[name] = e.currentTarget.value;
            newState[name] = e.currentTarget.value;
        }
        if(isNaN(e.currentTarget.value))
        {
            newState.error = "All inputs have to be numbers!"
            this.setState(newState);
            return;
        }
        let dataCopy = {...this.state, ...newState}
        newState.counts = getMaxCountByProgram(dataCopy);
        newState.error = checkValidation(newState.counts)? "":"There are less counts than the sequence currently needs - Currently: "+newState.counts;
        
        
        
        newState.maxCount = newState.counts;
        this.setState(newState);

        
        if (newState.error === "")
        {
            let valdiData =JSON.parse(JSON.stringify(newState));
            valdiData.maxCount = valdiData.counts;
            delete valdiData.error;
            delete valdiData.counts;
            setTopSeqCount(valdiData.maxCount);
            setMusicState(valdiData)
        }
            
    }

    setData = (checkValidation, setMusicState, setTopSeqCount) => (data) => {
        let newState = {};
        newState.duration = data.duration;
        newState.offsetInS = data.offsetInS;
        newState.bpm = data.bpm;
        newState.counts = data.counts;
        newState.chantTime = {chantStart: 0, chantEnd: 0}
        newState.error = checkValidation(newState.counts)? "":"There are less counts than the sequence currently needs - Currently: "+newState.counts;
        newState.musicUrl = data.musicUrl;
        this.setState(newState);
        if (newState.error === "")
        {
            let valdiData =JSON.parse(JSON.stringify(newState));
            valdiData.maxCount = valdiData.counts;
            delete valdiData.error;
            delete valdiData.counts;
            setTopSeqCount(valdiData.maxCount);
            setMusicState(valdiData)
        }
    }

    setInitialMusicData = (data) => {
        setTimeout(() => {if(!this.willUnmount)this.setState(data)}, 500);
    }

    render (){
        return (
            <Subscribe to={[SequenceContainer, TeamContainer, ProgramContainer]}>
            {
                (seq, team, program) =>(
                <div style= {{height: "100%", width: "100vw", alignItems: "center", display: "flex", justifyContent: "center", position: "absolute"}}>
                    {!this.state.isLoaded? this.setInitialMusicData({...program.getMusicData(), isLoaded: true}) : null}
                    <div style={{width: "50%", height: "100%", borderRightWidth: 2, alignItems: "center", display: "flex", justifyContent: "center",
                        border: "black", boxShadow: this.props.theme.boxShadow, background: this.props.theme.listBackgroundActive}}>
                        <Grid container alignItems="center" justify="center" direction="column">
                            <MusicSide programId={program.state.id} setData={this.setData(seq.checkNewMaxCountIsValid, program.setNewMusicData, seq.changeTopCount)} setBuffer={program.setBuffer}/>
                        </Grid>
                    </div>
                    <div style={{width: "50%"}}>
                        <CssBaseline/>
                        <Container fixed>
                            <ProgramSide data={this.state} onChangeData={this.onChangeData(seq.checkNewMaxCountIsValid, program.setNewMusicData, seq.changeTopCount)}/>
                        </Container>
                    </div>
                </div>
                )
            }</Subscribe>
        );
    }

}


export default withTheme(MusicHandler);