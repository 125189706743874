
import React,{useMemo, useCallback, useRef, useEffect} from 'react';
import { useDrag } from 'react-dnd'
import Participant from './ParticipantAvatar';
import { getEmptyImage } from 'react-dnd-html5-backend';

function Draggable (props){
    const {participant, currentMatData, forbidDrag} = props;
    const ref = useRef(null);

    const getRef = () =>{
      return ref;
    }
    const [{isDragging}, drag, preview] = useDrag(
        {
            canDrag: !forbidDrag,
            item: { id: participant.id, type: "ParticipantAvatar", participant, getRef},
            collect: monitor => ({
                isDragging: !!monitor.isDragging(),
            }),
      })

      useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
      }, [])

      const snapshot = {isDragging};
      /*const provided = useMemo(() => ({
        innerRef: drag,
      }));*/

      const provided = ({
        innerRef: ref,
      });
      drag(ref);

      return (
        <div ref={provided.innerRef} style={{opacity: isDragging? 0:1, }}>
            <Participant participant={participant} isSelected={currentMatData ? currentMatData.isSelected : false}/>
        </div>
      )
}

export default Draggable;

