import React from 'react';

import { withTheme } from '@material-ui/core/styles';
import { Subscribe } from 'unstated-no-context-polyfill';

import SequenceContainer from 'container/program/Sequence'
import TeamContainer from 'container/program/Team'
import ProgramContainer from 'container/program/Program'

import {withFirebase} from 'container/firebase'

import { AppBar, Toolbar, Typography, IconButton, Grid,Snackbar , makeStyles} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SaveIcon from '@material-ui/icons/Save';

import Alert from '@material-ui/lab/Alert';

import { Link } from 'react-router-dom';
import * as ROUTES from './../../../router/routes';

const useStyles = makeStyles((theme) => ({
    snackbar: {
        bottom: theme.spacing(12),
    },
}));
  


function TopBarSeq (props){
    const classes = useStyles();
    const [messageBox, setMessageBox] = React.useState({open: false, text: "", status: "success"})

    /*============================================================================
    ===============================   Childs   ===================================
    ==============================================================================*/

    const onSave = (id) =>(programGeneral, programWaypoints, sequences, team)=> async () =>{
        if (programGeneral.name === "")
        {
            setMessageBox({open: true, text: "Name is missing!", status: "error"});
            return;
        }

        let teams = [...team.activeTeamTemplates];
        let teamCopy = {...team};
        delete teamCopy.activeTeamTemplates;
        let data = {
            general: {...programGeneral, teams, lastEdit: new Date(), amountParticipants: Object.keys(teamCopy.active).length},
            team: teamCopy,
            paths: {
                sequences,
                waypoints: programWaypoints
            }
        }
        setMessageBox({open: true, text: "Saved successfully!", status: "success"})
        return await props.firebase.saveProgram(id)(data);
        
    }
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setMessageBox({open: false, text: messageBox.text, status: messageBox.status});
      };

    return (
        <Subscribe to={[SequenceContainer, TeamContainer, ProgramContainer]}>
            {
                (sequence, team, program) =>
                    <AppBar position="sticky" >
                        <Toolbar>
                            <Grid container justify="space-between" alignItems= 'center'>
                                <Grid item>
                                    <IconButton edge="start" aria-label="menu" component={Link} to={ROUTES.MAIN}>
                                        <NavigateBeforeIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item >
                                    <IconButton edge="start" color="inherit" onClick={sequence.zoomOut} disabled={sequence.state.path.length===0}>
                                        <ExpandLessIcon />
                                    </IconButton>
                                    <Typography variant="h6" style={{display: 'inline-block'}} >
                                        {sequence.state.visibleSequence.name + " ("+sequence.state.visibleSequence.counts+")"}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton edge="start"  onClick={
                                        onSave(program.state.id)(
                                            program.getProgramGeneralData(), 
                                            program.state.waypoints, 
                                            sequence.sequence,
                                            team.getTeamData())} >
                                        <SaveIcon />
                                    </IconButton>
                                    <Snackbar className={classes.snackbar} open={messageBox.open} autoHideDuration={80000} onClose={handleClose}>
                                        <Alert onClose={handleClose} severity={messageBox.status}>
                                            {messageBox.text}
                                        </Alert>
                                    </Snackbar>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
            }
        </Subscribe>
    )
}
export default withTheme(withFirebase(TopBarSeq));


/*
{sequence.state.path.length>-1? 
                                                <ButtonGroup orientation="vertical" style={{margin: 10}}>
                                                    <Button onClick={sequence.increaseCounts}>
                                                    <ArrowUpwardIcon fontSize="inherit" />
                                                    </Button>
                                                    <Button onClick={sequence.decreaseCounts}>
                                                        <ArrowDownwardIcon fontSize="inherit" />
                                                    </Button>
                                                </ButtonGroup>: null
                                            }*/