import React from 'react';
import { TextField,IconButton } from '@material-ui/core';
import { Draggable } from "react-beautiful-dnd";
import { withTheme } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SettingsIcon from '@material-ui/icons/Settings';
import CountInputField from './CountInputField';
import SequenceConfigDialog from './SequenceConfigDialog';

class Sequence extends React.Component {

    constructor (props){
        super(props);
        
        this.state = {
            sequenceConfigDialog: false
        }
        this.space = 2;
    }
    switchDialogState = (e) => {
        this.setState({sequenceConfigDialog: !this.state.sequenceConfigDialog})
    }

    
    /*============================================================================
    ===============================   Childs   ===================================
    ==============================================================================*/
    isCompressedView = (counts) => this.getHeight(counts) < 100;

    getHeight= (counts) => this.props.grid * counts -2;
    
    getItemStyle = (isDragging, draggableStyle, counts) =>(
        {
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        height: this.getHeight(counts),
        margin: `${this.space}px ${this.space}px 0 ${this.space}px`,
        paddingRight: `${this.space}px`,
        // change background colour if dragging
        background: isDragging ? this.props.theme.fieldColorActive : this.props.theme.fieldColor,
        position: "relative",
        resize: "both",
      
        // styles we need to apply on draggables
        ...draggableStyle
    })
    getFormStyle = () =>{
        if (this.isCompressedView(this.props.sequence.counts))
            return {paddingTop: 4, paddingLeft: 4, paddingRight: 4, position: "flex"}
        else
            return {paddingTop: 15, paddingLeft: 20, paddingRight: 20, position: "flex"}
    }


    render (){
        let name = this.props.index +"$"+this.props.groupName;
        return (
            <Draggable draggableId={this.props.sequence.id} index={this.props.index}>
                {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} 
                    style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style, this.props.sequence.counts)}>

                        <SequenceConfigDialog isOpen={this.state.sequenceConfigDialog} handleClose={this.switchDialogState} 
                        sequence={this.props.sequence} index={this.props.index} groupName={this.props.groupName}/>

                        <form style={this.getFormStyle()}>
                            <TextField margin="dense" style={{width: "80%", padding: 5}} label="Name" name={name +"$name"} defaultValue={this.props.sequence.name} onChange={this.props.onInputChange} variant="outlined"/>
                            <CountInputField sequence={this.props.sequence} name={name+"$counts"} onChange={this.props.onInputChange}/>
                            <div style={{width: "5%",float:"right"}}>
                                {this.isCompressedView(this.props.sequence.counts) ? 
                                <React.Fragment>
                                    <IconButton size="small" edge="start" color="inherit" name={name} onClick={this.switchDialogState} style={{display:"table-cell"}}>
                                        <SettingsIcon />
                                    </IconButton>
                                    <IconButton size="small" edge="start" color="inherit" name={name} onClick={this.props.zoom} style={{display:"table-cell"}}>
                                        <OpenInNewIcon />
                                    </IconButton>
                                </React.Fragment>: <p/>}
                            </div> 
                        </form>
                        {   !this.isCompressedView(this.props.sequence.counts) ? 
                            <form >
                                <div style={{position: "absolute", bottom: 0, width: "100%"}}>
                                    <div style={{justifyContent: "space-between", display: "flex", paddingLeft: 20, paddingRight: 20}}>
                                        <IconButton edge="start" color="inherit" name={name} onClick={this.switchDialogState}>
                                            <SettingsIcon />
                                        </IconButton>
                                        <IconButton edge="start" color="inherit" name={name} onClick={this.props.zoom}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </form> : null
                        }
                </div>)}
            </Draggable>
         )
    }
}
export default withTheme(Sequence);

