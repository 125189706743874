
import React, {} from 'react';

import { withTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';




const useStyles = makeStyles({
    root: {
        height: 240,
        flexGrow: 1,
        maxWidth: 400,
        textAlign: "left"
    }});

//backgroundImage: "linear-gradient(90deg, #90caf9 " + 60 + "%, #607790 " + 60 +"%)"
const styledTreeItem = percent => theme => makeStyles({
    root: {
        backgroundImage: "linear-gradient(90deg, "+  theme.progressActive+ " " + percent + "%, " +theme.progressPassive  + " " + percent +"%)"
    }
});


//backgroundImage: "linear-gradient(90deg, #90caf9 " + 60 + "%, #607790 " + 60 +"%)"
function StyledTreeItem(props) {
    const { labelText, labelInfo, startCount, counts, currentCounts, theme,...other } = props;
    let percent = 100*(currentCounts - startCount)/(counts-1)
    if(percent > 100)
        percent = 100;
    return (
        <TreeItem classes={{selected: styledTreeItem(percent)(theme)().root}} label={labelText} {...other}/>
    )
}



function SequenceSelectorList (props){
    const classes = useStyles();


    const onChangeSequence = (e, pathString) => {
        let path = pathString[0].split('$');
        let startCount = props.sequence.getSequenceBySimplePath(path).startCount;
        startCount = startCount > 0 ? startCount : 1;
        props.program.setMTimeByCount(startCount);
        props.program.setPath(props.sequence.getSequencePathByCount(startCount));
    }

    
///////////////////////// BUILD TREE ////////////////////////////////////
    const buildInnerTree = (sequences, path, k, currentCount) => (
        sequences[k].map((s, index) => 
        {
            let id= path.concat([index]).join('$')
            return(
                <StyledTreeItem key={id} nodeId={id} labelText={s.name + " (" + s.counts + ")"} startCount={s.startCount} counts={s.counts} currentCounts={currentCount} theme={props.theme}>
                {
                    (s.sequences && Object.keys(s.sequences).length) > 0 ? buildTree(s.sequences, path.concat([index]), currentCount) :null
                }
                </StyledTreeItem>
            )
        })
    )
    const buildTree = (sequences, path, currentCount) => 
    {
        var tree;
        const keys  = Object.keys(sequences);
        const isGroup = keys.length > 1;
        if (keys.length === 1 && sequences[keys[0]].length === 0)
            return null;
        
        tree = keys.map (k=>
        {
            let id= path.concat([k]).join('$');
            return (
                isGroup ? 
                <StyledTreeItem nodeId={id} key={id} labelText={k} theme={props.theme}>
                    {
                        buildInnerTree(sequences, path.concat([k]), k, currentCount)
                    }
                </StyledTreeItem> : buildInnerTree(sequences, path.concat([k]), k, currentCount)
            )
        });

        return tree;
    }
///////////////////////// BUILD TREE ////////////////////////////////////

    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            selected={props.program.getPaths()}
            expanded={props.program.getExpandedIds()}
            onNodeSelect={onChangeSequence}
            multiSelect
        >
        {
            buildTree(props.sequence.sequence.sequences,[], props.program.getCounts())
        }
        </TreeView>
    )
};

export default withTheme(SequenceSelectorList);