import React, {useState, useEffect} from 'react';

import TopBarSeq from './elements/TopBarSeq';
import EditingFooter from './elements/EditingFooter';

//import {getActiveProgram} from '../../data/program';
import {Provider} from 'unstated-no-context-polyfill';
import GeneralSettings from './GeneralSettings';
import ManageActiveTeam from './ManageActiveTeam';
import SequenceEditor from './SequenceEditor';
import MatEditor from './MatEditor';
import MusicHandler from './MusicHandler';
import ChantEditor from './ChantEditor';

import {getMaxCountByProgram,} from 'lib/ProgramCalculations'
import {removeArrayElement} from 'lib/Utilities'
import Slide from '@material-ui/core/Slide';
import EMPTY_PROGRAM from 'classes/templates/Program'

import ProgramContainer from '../../container/program/Program';
import TeamContainer from '../../container/program/Team';
import SequenceContainer from '../../container/program/Sequence';
import withAuthorization from '../../router/WithAuthorization';
import {withFirebase} from 'container/firebase';



let sequenceContainer;
let programContainer;
let teamContainer;

function EditingWrapper (props) {
        const [isLoaded, setIsLoaded] = useState(false);
        const [editMode, setEditMode] = useState({current: 0, prev: 0});
        

        const getData = async () => {
            if(!!props.firebase.auth.currentUser)
            {
                let program;
                if(props.location.state.programId !== null)
                {
                    program = await props.firebase.getProgram(props.location.state.programId)
                    program.general.id = props.location.state.programId;
                }
                else
                {
                    program = {...EMPTY_PROGRAM};
                    program.general.id = await props.firebase.createNewProgram();
                    program.team.availableTeamTemplates = await props.firebase.getTeams();
                    
                }
                let maxCounts = getMaxCountByProgram(program.general);
                program.general.maxCount = maxCounts;
                program.paths.sequences.counts = maxCounts;

                let activeIds = Object.keys(program.team.participants.active);
                let participantsStatusIds = [];
                activeIds.forEach(id => !program.team.participants.notSetted.includes(id)?participantsStatusIds.push(id): null )

                sequenceContainer = new SequenceContainer({
                    sequence: program.paths.sequences,
                })
                programContainer = new ProgramContainer({
                    general: {...program.general},
                    waypoints: {...program.paths.waypoints},
                    participantsStatusIds,
                })
                teamContainer = new TeamContainer({
                    team: program.team,
                })
                setIsLoaded(true);
            }
        }

        useEffect(() => {
            if(!isLoaded)
            {
                getData();
            }
        });

        


       /* window.onbeforeunload = function() {
            return "Data will be lost if you leave the page, are you sure?";
        };*/

   
    const onChangeModus = (event, newMode) => {
        event.preventDefault();
        sequenceContainer.setVisibleSequenceToTop();
        setEditMode({current: newMode, prev: editMode.current});
    }
   

    const getDirection = (id) =>{
        let result ={}
        if(editMode.current === id && editMode.current >= editMode.prev)
            result={direction: "left", in: true}
        else if (editMode.current === id && editMode.current < editMode.prev)
            result={direction: "right", in: true}
        else if(editMode.current > editMode.prev)
            result = {direction: "right", in: false}
        else if(editMode.current < editMode.prev)
            result = {direction: "left", in: false}
        return result;
    }


    if(!isLoaded)
        return (<div></div>);


    let i = 0;
    const speed = 1000;
    return (
        <Provider inject={[sequenceContainer, teamContainer, programContainer]}>
            <div style={{height: "100vh", display:"flex", flexDirection: "column"}}>
                <TopBarSeq />
                <div style={{height: "100%", width: "100vw", position: "relative"}}>
                    <Slide {...getDirection(i)} timeout={{enter: speed, exit: speed/2}} mountOnEnter unmountOnExit><GeneralSettings/></Slide>
                    <Slide {...getDirection(++i)} timeout={{enter:speed, exit: speed/2}} mountOnEnter unmountOnExit><ManageActiveTeam /></Slide>
                    <Slide {...getDirection(++i)} timeout={{enter: speed, exit: speed/2}} mountOnEnter unmountOnExit><MusicHandler/></Slide>
                    {programContainer.state.containsChant ? 
                        <Slide {...getDirection(++i)} timeout={{enter: speed, exit: speed/2}} mountOnEnter unmountOnExit><ChantEditor/></Slide>
                        : null
                    }
                    <Slide {...getDirection(++i)} timeout={{enter: speed, exit: speed/2}} mountOnEnter unmountOnExit><SequenceEditor/></Slide>
                    <Slide {...getDirection(++i)} timeout={{enter: speed, exit: speed/2}}  mountOnEnter unmountOnExit><MatEditor/></Slide>
                </div>
                <EditingFooter containsChant={programContainer.state.containsChant} value={editMode.current} onChange={onChangeModus}/>
            </div>
        </Provider>
    );

    
}
//                    
export default withAuthorization(authUser => !!authUser)(withFirebase(EditingWrapper));