import React from 'react';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AddIcon from '@material-ui/icons/Add';

import { Fab } from '@material-ui/core';

import GroupAddIcon from '@material-ui/icons/GroupAdd';

function SequenceAddButtons (props)
{
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleOnAddSequence = groupName => e => {
        props.onClickSequence(groupName);
        handleClose();
    }

    return (

        <div style={{position: "absolute", width: "150px", right: -150}}>
            <div style={{position: "fixed", display: "flex", width: "120px", justifyContent: "space-between", bottom: "72px", padding: 20}}>
                {
                    Object.keys(props.sequences).length > 1 ? 
                        <SpeedDial
                            ariaLabel="SpeedDial tooltip example"
                            icon={<SpeedDialIcon />}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            open={open}
                            name={"wat"}
                        >
                            {Object.keys(props.sequences).map((g) => (
                            <SpeedDialAction
                                id={g}
                                key={g}
                                icon={<AddIcon/>}
                                tooltipTitle={g}
                                tooltipOpen
                                onClick={handleOnAddSequence(g)}
                            />
                            ))}
                        </SpeedDial>:
                        <Fab onClick={handleOnAddSequence(Object.keys(props.sequences)[0])}>
                            <AddIcon />
                        </Fab>
                }
                {
                    props.showAddGroup ? 
                    <Fab onClick={props.onClickGroup} style={{position: "absolute", right: 0, bottom: 20}}>
                        <GroupAddIcon />
                    </Fab>:null
                }                              
                
            </div>
        </div>
    );
}


export default SequenceAddButtons;