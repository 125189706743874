
import React, { useState, Component, useEffect } from 'react';
import { makeStyles, Paper, Tabs, Tab, Slide } from '@material-ui/core/';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import DvrIcon from '@material-ui/icons/Dvr';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import ProgramSelection from './ProgramSelection';
import TeamSelection from './TeamSelection';

const useStyles = makeStyles({
  root: {
    position: "absolute",
    bottom: 0,
    flexGrow: 1,
    width: "100%",
  },
});


function SelectionWrapper(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const getDirection = (id) => {
    let result = {}
    if (this.state.editMode === id && this.state.editMode >= this.lastEditMode)
      result = { direction: "left", in: true }
    else if (this.state.editMode === id && this.state.editMode < this.lastEditMode)
      result = { direction: "right", in: true }
    else if (this.state.editMode > this.lastEditMode)
      result = { direction: "right", in: false }
    else if (this.state.editMode < this.lastEditMode)
      result = { direction: "left", in: false }
    return result;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let i = 0;
  const speed = 500;
  return (
    <React.Fragment>
      {
        value === 0 ? <ProgramSelection /> : <TeamSelection />
      }

      <Paper square className={classes.root}>

        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="icon label tabs example"
        >
          <Tab icon={<DvrIcon />} label="Programs" />
          <Tab icon={<SupervisorAccountIcon />} label="Teams" />
          <Tab icon={<PersonPinIcon />} disabled label="Leaders (coming soon)" />
        </Tabs>
      </Paper>
    </React.Fragment>

  )
}

export default withRouter(SelectionWrapper); 