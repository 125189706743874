
import React from 'react';
import { Subscribe } from 'unstated-no-context-polyfill';

import { withTheme,makeStyles, Box, Grid } from '@material-ui/core';

import TeamContainer from 'container/program/Team';
import SequenceContainer from 'container/program/Sequence';
import ProgramContainer from 'container/program/Program';

import Droppable from 'lib/DnD/Droppable';

import ParticipantDraggable from './ParticipantDraggable';
import ParticipantDragLayer from './ParticipantDragLayer';
import { Container } from '@material-ui/core';

//import Participant from '../Group/Participant';
const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        width: "20%",
        position: "absolute",
        right: 0,
        display:"flex",
        flexWrap: "wrap",
        alignContent: "flex-start",
        '& > *': {
        margin: theme.spacing(2),
      },
    },
}));

function ParticipantContainer (props){
    //
    const classes = useStyles();

    const onEndDrag = (addParticipantToUnsetted, removeParticipantFromMat)=>(p) => {
        addParticipantToUnsetted(p.id)
        removeParticipantFromMat(p.id);
    }
    return (
        <Subscribe to={[SequenceContainer, TeamContainer, ProgramContainer]}>
        {
            (sequence, team, program) => (
                <Droppable type={"ParticipantAvatar"} onEndDrag={onEndDrag(team.addParticipantToUnsetted, program.removeParticipantFromMat)} droppableId="ParticipantField">
                    {(provided, snapshot) => 
                        <React.Fragment>
                            <ParticipantDragLayer/>
                            <Container ref={provided.innerRef} className={classes.container}
                            style={{boxShadow: props.theme.boxShadow, background: !snapshot.isDraggingOver ? props.theme.listBackgroundActive: props.theme.listBackgroundPassive}}>
                                {
                                    team.state.participants.notSetted.map ((id, index)=> 
                                        <ParticipantDraggable participant={team.state.participants.active[id]} key={index} forbidDrag={program.state.isPlaying}/>
                                    )
                                }
                            </Container>
                        </React.Fragment>
                    }
                </Droppable>
            )
        }
        </Subscribe>
    )
};
//{provided.placeholder}
export default withTheme(ParticipantContainer);
