import React, {useState, useEffect} from 'react';

import {withFirebase} from './../container/firebase';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import * as ROUTES from './routes';

import StartMenu from '../components/menu/StartMenu'
import Login from '../components/menu/Login'
import Program from '../components/editing/EditingWrapper'
import Teams from '../components/teamEditing/EditingWrapper'

import AuthUserContext from './context';

const RouterSwitch = (props) => {
    
    const [authUser, setAuthUser] = useState("init");

    useEffect(() =>{
        props.firebase.auth.onAuthStateChanged(async firebaseUser => {
            if(firebaseUser)
            {
                let userData = await props.firebase.getInitialData();
                setAuthUser(firebaseUser)
            }
            else
                setAuthUser(null) 
        });
    },[])

    
        

    const customHistory = createBrowserHistory();
    const condition = authUser => !!authUser;

    return(
        <AuthUserContext.Provider value={authUser}>
            <Router>
                <Switch>
                    <Route exact path={ROUTES.EDITING} component={ Program }/>
                    <Route exact path={ROUTES.MAIN} component={ StartMenu }/>
                    <Route exact path={ROUTES.LOGIN} component={ Login }/>
                    <Route exact path={ROUTES.TEAMS} component={ Teams }/>
                </Switch>
            </Router>
        </AuthUserContext.Provider>
    )
};

export default withFirebase(RouterSwitch);