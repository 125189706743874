import React from 'react';
import { withRouter } from 'react-router-dom';

 
import AuthUserContext from './context';
import * as ROUTES from './routes';
 
const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {

    
    render() {
      
      return (
        <AuthUserContext.Consumer>
          {authUser =>

          <React.Fragment>
            {condition(authUser) ? <Component {...this.props} /> : authUser==="init" ? null : this.props.history.push(ROUTES.LOGIN)}
          </React.Fragment>
          }
          
        </AuthUserContext.Consumer>
      );
    }
  }
  return withRouter(WithAuthorization);
};
 
export default withAuthorization;