import React, {useState} from 'react';

import {Button, Grid, TextField, Switch, FormControlLabel, Typography} from '@material-ui/core/';
import { Alert, AlertTitle } from '@material-ui/lab';




export default function ProgramSide (props) {

    return (
        <Grid container spacing={2} style={{textAlign: "left"}}>
            <Grid item xs={10}>
                {props.data.error && props.data.error != "" ?
                    <Alert severity="error" style={{marginLeft: 20}}>
                        {props.data.error}
                    </Alert>:
                    <Typography>Counts: {props.data.counts}</Typography>
                }
            </Grid>
            <Grid item xs={5}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="bpm"
                    label="BPM"
                    name="bpm"
                    margin="dense"
                    autoComplete="BPM"
                    value={props.data.bpm}
                    onChange={props.onChangeData}
                />
            </Grid>
            <Grid item xs={5}>
                <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="duration"
                        label="Duration in s"
                        name="duration"
                        margin="dense"
                        autoComplete="Duration"
                        value={props.data.duration}
                        onChange={props.onChangeData}
                />
            </Grid>
            <Grid item xs={5} sm={5}>
                <TextField variant="outlined"
                    fullWidth
                    id="musicOffset"
                    label="Countstart in s"
                    name="musicOffset"
                    margin="dense"
                    value={props.data.musicOffset}
                    onChange={props.onChangeData}
                />
            </Grid>
            <Grid item xs={5} sm={5}/>
            <Grid item xs={5}>
                <FormControlLabel 
                    name="containsChant"
                    control={<Switch checked={props.data.containsChant} onChange={props.onChangeData}/>} 
                    label="contains Chant"
                />
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={5} sm={5}>
                <TextField variant="outlined"
                    fullWidth
                    id="chantStart"
                    label="Chant Start in s"
                    name="chantStart"
                    margin="dense"
                    value={props.data.chantTime.chantStart}
                    onChange={props.onChangeData}
                    disabled={!props.data.containsChant}
                />
            </Grid> 
            <Grid item xs={5} sm={5}>
                <TextField
                    variant="outlined"
                    fullWidth
                    id="chantEnd"
                    label="Chant Ende in s"
                    name="chantEnd"
                    margin="dense"
                    value={props.data.chantTime.chantEnd}
                    onChange={props.onChangeData}
                    disabled={!props.data.containsChant}
                />
            </Grid> 
        </Grid>

    )
}