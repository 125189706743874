import React from 'react';

//import { TextField } from '@material-ui/core';

import TeamContainer from '../../container/program/Team';
import SequenceContainer from '../../container/program/Sequence';
import { Subscribe } from 'unstated-no-context-polyfill';


import ActiveParticipantField from './elements/team/ActiveParticipantField'
import RightPanel from './elements/team/RightPanel';
import { DragDropContext } from "react-beautiful-dnd";
import ProgramContainer from '../../container/program/Program';
import { Grid } from '@material-ui/core';


class ManageActiveTeam extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Subscribe to={[SequenceContainer, TeamContainer, ProgramContainer]}>
                {
                    (sequence, team, program) =>
                        (
                            <div style={{ width: "100vw", height: "100%", display: "flex", position: "absolute", }}>
                                <DragDropContext onDragEnd={team.onDragEndTeamManagement(program.removeParticipantFromMat)} autoScroll={false}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={9}>
                                            <ActiveParticipantField />
                                        </Grid>
                                        <Grid item xs={3} style={{ height: "100%" }}>
                                            <RightPanel />
                                        </Grid>
                                    </Grid>
                                </DragDropContext>
                            </div>
                        )
                }
            </Subscribe>
        );

    }
}

export default ManageActiveTeam;


/*import React, {useState} from 'react';

//import { TextField } from '@material-ui/core';

import TeamContainer from '../../container/program/Team';
import SequenceContainer from '../../container/program/Sequence';
import { Subscribe } from 'unstated-no-context-polyfill';


import ActiveParticipantField from './elements/team/ActiveParticipantField'
import RightPanel from './elements/team/RightPanel';
import { DragDropContext } from "react-beautiful-dnd";
import ProgramContainer from '../../container/program/Program';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

}));

function ManageActiveTeam (props) {

    const [success, setSuccess] = useState(null);

    return (
        <Subscribe to={[SequenceContainer, TeamContainer, ProgramContainer]}>
        {
            (sequence, team, program) =>
            (
                <div style={{width: "100vw", height:"100%", display: "flex", position: "absolute", }}>
                    <DragDropContext onDragEnd={team.onDragEndTeamManagement(program.removeParticipantFromMat)} autoScroll={false}>
                    <Grid container spacing={0}>
                        <Grid item xs={9}>
                            <ActiveParticipantField/>
                        </Grid>

                        <Grid item xs={3}>
                            <RightPanel />
                        </Grid>
                    </Grid>
                    </DragDropContext>
                </div>
            )
        }
        </Subscribe>
    );
}

export default ManageActiveTeam;*/