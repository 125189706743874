const EMPTY_PROGRAM = {
    general:{
        name : "",
        bpm : 140,
        maxCount : 362,
        containsChant : false,
        chantTime : {chantStart: 0, chantEnd: 30},
        musicOffset: 0,
        oneCountStart: 1,
        duration : 150,
        matSize : {x: 12, y: 12},
        musicUrl : "",
        teams: [],

    },

    team: {
        activeTeamTemplates: [],
        availableTeamTemplates: [],
        participants: {
            active: {},
            inactive: {},
            notSetted: [],
        }
    },

    paths: {
        sequences: {
            counts: 362,
            name: "",
            sequences: {"All": []},
            startCount: 1,
            teams: [],
        },
        waypoints: {},
    }
}

export default EMPTY_PROGRAM;