import React from 'react';
import { makeStyles, Box,List, ListItem, ListItemIcon, ListItemText, Divider  } from '@material-ui/core/';

import SettingsIcon from '@material-ui/icons/Settings';
import ContactMailIcon from '@material-ui/icons/ContactMail';

const useStyles = makeStyles((theme) => ({
    panel:{
        width: "25%",
        height: "100%",
        background: theme.palette.info.main
    },
}));





function LeftPanel(props) {
    const classes = useStyles();


    const handleListItemClick = (event, index) => {
        props.setSelectedMenu(index);
      };

    return (
       <Box className={classes.panel}>
            <List>
                <ListItem button selected={props.selectedMenu === 0} onClick={(event) => handleListItemClick(event, 0)}>
                    <ListItemIcon>
                        <ContactMailIcon />
                    </ListItemIcon>
                    <ListItemText primary="Athlets" />
                </ListItem>
                <ListItem button selected={props.selectedMenu === 1} onClick={(event) => handleListItemClick(event, 1)}>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                </ListItem>
           </List>
           <Divider />

       </Box>
    );
}


export default LeftPanel;